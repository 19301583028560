import {
  Component,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from '../../../service/auth.service';
import {DBService} from '../../../service';

@Component({
  template: ``
})
export class SignoutComponent implements OnInit {

  constructor(private authService: AuthService,
              private dbService: DBService,
              private router: Router) { }

  ngOnInit() {
    this.recordLogoutData();
    this.authService.signout();
  }

  recordLogoutData() {
    console.log('Attempting to record login');
    sessionStorage.removeItem('xyz');
    sessionStorage.removeItem('login-data');
    sessionStorage.removeItem('analysis-data');
    this.dbService.recordAuthEvent('logout').subscribe((data: string) => {
      console.log('RECORDED LOGOUT DATA', data);
    });
  }
}
