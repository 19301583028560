<card *ngIf="reportAnalysis.length > 0" [title]="'Report Analysis'" class="mb-0">
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
    </mat-form-field>

    <mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
        <ng-container matColumnDef="fileName">
            <mat-header-cell *matHeaderCellDef> File Name </mat-header-cell>>
            <mat-cell  *matCellDef="let element">
                <div *ngIf="element.analysis_status === 'Duplicate'; else linkRow">
                    {{element.file_name}}
                </div>
                <ng-template #linkRow>
                    <a [routerLink]="['/default-layout/molecule-analysis', element.analysis_id, element.user_id, {type: 'previous'}]">{{element.file_name}} </a>
                </ng-template>

            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="analysisDate">
            <mat-header-cell *matHeaderCellDef > Analysis Date (UTC Time Zone)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.analysis_date }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="projectCode">
            <mat-header-cell *matHeaderCellDef> Project </mat-header-cell>
            <mat-cell *matCellDef="let element">{{element.project_code}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.analysis_status}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns;"></mat-row>
    </mat-table>
    <mat-paginator #RApaginator [length]="reportAnalysis.length" [pageSizeOptions]="[10, 20, 50]"  showFirstLastButtons></mat-paginator>
</card>








