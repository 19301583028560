import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {MaterialModule} from './pages/material-components/material.module';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import { ChartsModule } from 'ng2-charts';
import { CognitoUtil } from '../app/service/cognito.service'

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {DefaultLayoutComponent} from './layouts/default/default.component';
import {ExtraLayoutComponent} from './layouts/extra/extra.component';
// Red Thread Components
import {NavbarComponent} from './rt-components/navbar/navbar.component';
import {SidebarComponent} from './rt-components/sidebar/sidebar.component';
import {LogoComponent} from './rt-components/logo/logo.component';
import {MainMenuComponent} from './rt-components/main-menu/main-menu.component';
import {RTCardComponent} from './rt-components/card/card.component';
import {BadgeComponent} from './rt-components/badge/badge.component';
import {BreadcrumbComponent} from './rt-components/breadcrumb/breadcrumb.component';
import {FileComponent} from './rt-components/file/file.component';
// Red Thread Pages
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import {PreviousAnalysisComponent } from './pages/previous-analysis/previous-analysis.component';
import {AnalysisDetailsComponent } from './pages/analysis-details/analysis-details.component';
import {SmallMoleculeComponent } from './pages/small-molecule/small-molecule.component';
import {PageNotFoundComponent} from './pages/not-found/not-found.component';
import {DownloadComponent} from './pages/download';

import {FooterComponent} from './rt-components/footer/footer.component';
import {HeaderComponent} from './pages/home/header/component';


// Extra pages
import {PageSignIn1Component} from './pages/extra-pages/sign-in-1/sign-in-1.component';
import {Page500Component} from './pages/extra-pages/page-500/page-500.component';
import {Page404Component} from './pages/extra-pages/page-404/page-404.component';
import {
    FirstTimePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SigninComponent,
    SignoutComponent
} from './pages/auth';

import {LoadingComponent} from './pages/loading/component';
import {FileSizePipe} from '../utils';
import {DocUploadComponent, FileUploadComponent} from './pages/doc-upload';
import {HomeComponent} from './pages/home/home.component';

import {MatRadioModule} from '@angular/material/radio';
import {MatBadgeModule} from '@angular/material/badge';
import {AuthInterceptor} from './shared/auth.interceptor';
import { NewAnalysisComponent } from './pages/new-analysis/new-analysis.component';
import {MatSortModule} from '@angular/material/sort';
import { ChartsComponent} from './pages/dashboard/charts/charts.component';
import { ShowTableComponent } from './pages/previous-analysis/show-table/show-table.component';
import { SignupComponent } from './pages/auth/signup/signup.component';
import { VerificationComponent } from './pages/auth/signup/verification/verification.component';
import { ChromatogramsComponent } from './pages/chromatograms/chromatograms.component';
import { UserComponent } from './pages/user/user.component';
import { DetailsComponent } from './pages/user/details/details.component';
import { AuthLogComponent } from './pages/user/auth-log/auth-log.component';
import { TemplateComponent } from './pages/template/template.component';
import { MfaComponent } from './pages/auth/mfa/mfa.component';
import { TemplateService } from './service/template.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatNativeDateModule } from '@angular/material/core';
// import { NgxEchartsModule } from 'ngx-echarts';
import { GraphComponent } from './pages/small-molecule/graph/graph.component';
import { DialogComponent } from './pages/dashboard/dialog/dialog.component'
import { GraphDialogComponent } from './pages/small-molecule/graph-dialog/graph-dialog.component';
import {AppLoaderService} from './service/app-loader/app-loader.service'
import {AppLoaderComponent} from './service/app-loader/app-loader.component';
import { DialogHelpComponent } from './rt-components/navbar/dialog-help/dialog-help.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HelpDialogComponent } from './pages/doc-upload/help-dialog/help-dialog.component';
import { DynamicFormBuilderModule } from './pages/template/dynamic-form-builder/dynamic-form-builder.module';
import { ToastrModule } from 'ngx-toastr';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailComponent } from './pages/blog/blog-detail/blog-detail.component';
import { CrouselComponent } from './pages/home/crousel/crousel.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Page403Component } from './pages/extra-pages/page403/page403.component';
import { UserPageComponent } from './user-page/user-page.component';
// import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import awsconfig from '../environments/environment';
import Amplify from 'aws-amplify';
import { UserSignInComponent } from './pages/amplify-auth/user-sign-in/user-sign-in.component';
import { UserSignOutComponent } from './pages/amplify-auth/user-sign-out/user-sign-out.component';
import { UserNewPasswordComponent } from './pages/amplify-auth/user-new-password/user-new-password.component';
import { UserForgotPasswordComponent } from './pages/amplify-auth/user-forgot-password/user-forgot-password.component';

Amplify.configure(awsconfig);




@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MaterialModule,
        MatBadgeModule,
        ChartsModule,
        MatButtonModule,
        MatSortModule,
        MatRadioModule,
        NgIdleKeepaliveModule.forRoot(),
        ModalModule.forRoot(),
        FlexLayoutModule,
        DynamicFormBuilderModule,
        ToastrModule.forRoot(),
        NgbModule,
    ],
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        ExtraLayoutComponent,

        NavbarComponent,
        SidebarComponent,
        LogoComponent,
        MainMenuComponent,
        RTCardComponent,
        BadgeComponent,
        BreadcrumbComponent,
        FileComponent,

        DashboardComponent,

        PageNotFoundComponent,
        PageSignIn1Component,
        Page404Component,
        Page500Component,

        PreviousAnalysisComponent,
        FooterComponent,
        HeaderComponent,
        SigninComponent,
        SignoutComponent,
        HomeComponent,
        LoadingComponent,
        FileSizePipe,
        FileUploadComponent,
        DocUploadComponent,
        DownloadComponent,
        PreviousAnalysisComponent,
        AnalysisDetailsComponent,
        SmallMoleculeComponent,
        NewAnalysisComponent,
        ChartsComponent,
        ShowTableComponent,
        FirstTimePasswordComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        SignupComponent,
        VerificationComponent,
        ChromatogramsComponent,
        UserComponent,
        DetailsComponent,
        AuthLogComponent,
        TemplateComponent,
        MfaComponent,
        GraphComponent,
        DialogComponent,
        GraphDialogComponent,
        AppLoaderComponent,
        DialogHelpComponent,
        HelpDialogComponent,
        BlogComponent,
        BlogDetailComponent,
        CrouselComponent,
        Page403Component,
        UserPageComponent,
        UserSignInComponent,
        UserSignOutComponent,
        UserNewPasswordComponent,
        UserForgotPasswordComponent,
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS , useClass: AuthInterceptor, multi: true},
        MatNativeDateModule, AppLoaderService, CognitoUtil
    ],
    exports: [
        MaterialModule,
        MatSortModule,
        [
        // LoaderDialogComponent
        ],
    ],
    bootstrap: [AppComponent],
    entryComponents: [DialogComponent, GraphDialogComponent, HelpDialogComponent, GraphComponent, AppLoaderComponent, DialogHelpComponent, ]
})

export class AppModule {
}
