<div class="row">
    <div class="col-md-6"><strong>Analysis Type:</strong>
        {{getDisplayString(searchResult.analysisType)}} </div>
</div>
<br>
<br>


<div *ngIf="flag; else button">
    <div>
        <card [bgColor]="'success'" [color]="'Warn'" [outline]="true">
            <p><strong>Red Thread Analysis Result - &nbsp;</strong> Analysis results are grouped by sections found in
                the
                report. In each section findings are categorised based in severity.</p>
            <p>
                <badge [customColor]="'#EEC2B7'">Red</badge>
                Validation : Failed
            </p>
            <p>
                <badge [customColor]="'#F8F8A9'">Yellow</badge>
                Validation : Review Requested
            </p>
            <p>
                <badge [customColor]="'#C5EEB2'">Green</badge>
                Validation : Passed
            </p>
        </card>
    </div>

    <div *ngFor="let file of files; let j = index" [attr.data-index]="j">
        <div *ngIf="file.type === 'XLSX'">
            <a target="_blank" href="{{getSingedURL(file.key)}}">
                <file [type]="'xlsx'" [title]="'Result'"></file>
            </a>
        </div>
    </div>

    <div>
        <div><strong>Disclaimer</strong></div>
        <div style='font-size:80%;'>Red Thread is not a replacement for any quality control or quality
            assurance process. Ariadne does not guarantee the accuracy of the
            results of the software. To the fullest extent permitted under
            applicable law Ariadne is not liable for any event or loss related
            to the reliance on Red Thread or the output produced by Red Thread.</div>
    </div>

    <br><br><br><br>
    <div>

        <mat-accordion class="example-headers-align">
            <mat-expansion-panel *ngFor="let result of results let i = index" [attr.data-index]="i">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p><strong>{{result.file_name}} Results</strong></p>
                    </mat-panel-title>

                </mat-expansion-panel-header>


                <table class="table table-bordered mb-0">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">First</th>
                            <th scope="col">Last</th>
                            <th scope="col">Handle</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of result.analysis_result; let i = index" [attr.data-index]="i">
                            <th scope="row">{{i + 1}}</th>
                            <td>{{item.test_name}}</td>
                            <td>
                                <badge [customColor]="getColor(item.category)">
                                    {{item.category}}
                                </badge>
                            <td>{{item.details}}</td>
                        </tr>
                    </tbody>
                </table>
                <br><br>
                <div *ngIf="result.graphs.length > 0" class="row">
                    <div class="col-md-2">
                        <a target="_blank" href="{{getSingedURL(result.graphs[1].key)}}">
                            <mat-icon>assessment</mat-icon>
                            <strong>Overview</strong>
                        </a>
                    </div>
                    <div class="col-md-2">
                        <a target="_blank" href="{{getSingedURL(result.graphs[0].key)}}">
                            <mat-icon>assessment</mat-icon>
                            <strong>Results</strong>
                        </a>
                    </div>

                    <div class="col-md-3">
                        <a target="_blank" href="{{getSingedURL(result.graphs[2].key)}}">
                            <mat-icon>assessment</mat-icon>
                            <strong>Regression Line</strong>
                        </a>
                    </div>
                </div>

            </mat-expansion-panel>
        </mat-accordion>
        <!--        <br>
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel *ngIf="files">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p><strong>Visualization</strong></p>
                    </mat-panel-title>

                </mat-expansion-panel-header>
&lt;!&ndash;                <div *ngFor="let file of files; let j = index" [attr.data-index]="j">
                    <div *ngIf="file.type === 'GRAPH'">

                    <div class="row">
                        <div class="col-md-2"><strong>Analysis Type:</strong>
                            <button mat-raised-button color="accent" (click)="downloadFile(file.key)">Refresh</button>
                        </div>
                        <div class="col-md-10"><strong>Analysis Type:</strong>
                            {{getDisplayString(searchResult.analysisType)}}
                        </div>
                    </div>
                    </div>
                </div>&ndash;&gt;
                <table class="table table-bordered mb-0">
                    <thead>
                    <tr>
                        <th scope="col">File</th>
                        <th scope="col">Downlaod</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let file of files; let j = index" [attr.data-index]="j">
                        <td *ngIf=" file.type === 'GRAPH'">{{getFileName(file.key)}}</td>

                        <td *ngIf="file.type === 'GRAPH'">
                            <a target="_blank" href="{{getSingedURL(file.key)}}">
                                <file [title]="'GRAPH'" [image]="'assets/content/tablet.svg'" [size]="'small'"></file>
                            </a>
                           &lt;!&ndash;  <div>
                                 <button mat-raised-button color="accent" (click)="downloadFile(file.key)">Refresh</button>
                             </div>&ndash;&gt;
                        </td>

                    </tr>
                    </tbody>
                </table>


            </mat-expansion-panel>
        </mat-accordion>
        <br><br>
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel *ngIf="files">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p><strong>Data Download</strong></p>
                    </mat-panel-title>

                </mat-expansion-panel-header>
                <table class="table table-bordered mb-0">
                    <thead>
                    <tr>
                        <th scope="col">File</th>
                        <th scope="col">Downlaod</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let file of files; let j = index" [attr.data-index]="j">
                        <td *ngIf=" file.type === 'CSV'">{{getFileName(file.key)}}</td>

                        <td *ngIf="file.type === 'CSV'">
                            <a target="_blank" href="{{getSingedURL(file.key)}}">
                                <file [title]="'DATA'" [image]="'assets/content/csv.jpeg'" [size]="'small'"></file>
                            </a>
                            &lt;!&ndash;<div>
                                <button mat-raised-button color="accent" (click)="downloadFile(file.key)">Refresh</button>
                            </div>&ndash;&gt;
                        </td>

                    </tr>
                    </tbody>
                </table>


            </mat-expansion-panel>
        </mat-accordion>-->

    </div>
</div>
<ng-template #button>
    <div class="main-div">
        <div>
            <card [bgColor]="'info'" [color]="'info'" [outline]="true">
                <p><strong>Average PDF processing time is a single file is approximately 3-5 minutes.
                        Please click "Refresh" button to refresh result or use "Previous Analysis" link in the menu
                        later
                        to get the result
                    </strong> </p>
                <div>
                    <button mat-raised-button color="accent" (click)="populateResultData()">Refresh</button>
                </div>
            </card>
        </div>
    </div>
    <div>
        <mat-spinner style="margin:0 auto;" mode="indeterminate" [diameter]="200"></mat-spinner>
    </div>
</ng-template>
<!--<br><br><br><br><br><br><br><br>
<div [innerHTML]="multilinestr"></div>-->
{{pie_chart}}