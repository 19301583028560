<div *ngIf="name === 'BAR'">
  <h6 mat-dialog-title>About Chart</h6>
  <div mat-dialog-content>Click on the date/bar to see details on all the reports that were run that day in the section
    below - Reports Processed on 'Selected Date'. Click on a module key to select/deselect visual representation of the
    module in the bar graph</div>
</div>
<div *ngIf="name === 'LINE'">
  <h6 mat-dialog-title>“Selected Date” Analytics</h6>
  <div mat-dialog-content>Click on the data points for Error, Warning or Information flags for any reports and see the
    detailed analysis for each section in the section below in "Analysis Report/Data - 'Report ID'"</div>
</div>
<div *ngIf="name === 'Link'">
  <!-- <h6 mat-dialog-title>“Selected Date” Analytics</h6> -->
    <iframe width="560" height="315" title="image" src="https://www.youtube.com/embed/9801yPOjkOA" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen="false" background="none"></iframe>
</div>
<div *ngIf="name === 'Data send Successfully'">
  <!-- <h6 mat-dialog-title>“Selected Date” Analytics</h6> -->
  <div mat-dialog-content> You have successfully submitted the data! </div>
  <div mat-dialog-actions style="justify-content: center;">
    <button mat-button mat-dialog-close [routerLink]="['/default-layout/dashboard']">Close</button>
  </div>
</div>

<div *ngIf="name === 'expiryError'">
  <h4 mat-dialog-title>Password Expiry Warning!</h4>
  <div mat-dialog-content>
    Dear user your password will be expired in {{ days }} days.<br>Please reset it.
  </div>
  <div class="d-flex flex-row">
    <button class="my-5" mat-raised-button color="accent" mat-dialog-close [routerLink]="['/extra-layout/forgot-password', {name: 'reset'}]">Reset Password</button>
  </div>
</div>

<div *ngIf="name === 'password expired'">
  <h4 mat-dialog-title>Password Expired!</h4>
  <div mat-dialog-content>
    Dear user your password is expired. <br>Please reset password.
  </div>
  <button class="my-5" mat-raised-button color="accent" mat-dialog-close [routerLink]="['/extra-layout/forgot-password', {name: 'reset'}]">Reset Password</button>
</div>