

  <div class="main-div">



<div class="mat-div">
    <mat-form-field appearance="fill" class='matwidth'>
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker" >
        <input matStartDate placeholder="Start date"  #dateRangeStart>
        <input matEndDate placeholder="End date"  #dateRangeEnd  >
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
        <br>
    <button class="btn-success" (click)='selectedDates(dateRangeStart, dateRangeEnd)' >submit</button>
    <br>
  </div>










    <!-- <div [style.display]="pageLoaded? 'inline' : 'none'"> -->
    <div *ngIf="pageLoaded ; else spin">
      <div *ngIf="previousAnalysisKeys.length > 0; else spin">
        <app-show-table [searchResults]="previousAnalysisKeys"></app-show-table>
      </div>
      <!-- <ng-template #noDataFound>
        <div>
          <h3>No data found for {{signedInUser.username}}</h3>
          <br><br><br><br>
          <p><strong>This section will be active after analyzing report/data</strong></p>-->
        <!-- </div>
      </ng-template> --> 
    </div>

    <ng-template #spin>
      <div><mat-spinner  style="margin:0 auto;"
                         mode="indeterminate"  [diameter]="200"></mat-spinner></div>
    </ng-template>
  </div>

