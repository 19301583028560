<div  >

<div class="mat-display">
  <mat-form-field appearance="fill" class='matwidth'>
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [rangePicker]="picker" >
      <input matStartDate placeholder="Start date"  #dateRangeStart>
      <input matEndDate placeholder="End date"  #dateRangeEnd  >
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>


  <mat-form-field class="matwidth" appearance="fill" >
    <mat-label> Enter User ID</mat-label>
    <input matInput #UserID>
  </mat-form-field>
</div>

<br>
  <button class="btn-success" (click)='selectedLogDates(dateRangeStart, dateRangeEnd,UserID)' >submit</button>
  





<h3>Login Data</h3>

<div *ngIf="showTable ; else logspin">
<card class="mb-0"  >
 
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
  
  <div class="col-md-4 text-right">
    <button class="btn btn-success btn-block" (click)="openPDF()">Download PDF</button>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortStart="desc" id="authData"  >

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="userId">
      <th mat-header-cell *matHeaderCellDef > User ID </th>
      <td mat-cell *matCellDef="let element">{{element.userId}}
    </ng-container>

    <ng-container matColumnDef="activityDate">
      <th mat-header-cell *matHeaderCellDef > Activity Date (UTC Time Zone)</th>
      <td mat-cell *matCellDef="let element">{{element.creationDate}}
    </ng-container>

    <ng-container matColumnDef="eventResponse">
      <th mat-header-cell *matHeaderCellDef > Request Status</th>
      <td mat-cell *matCellDef="let element">{{element.eventResponse}}
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
  </table>
  <mat-paginator  [pageSizeOptions]="[ 10, 20, 50]"  [pageSize]="10"  showFirstLastButtons></mat-paginator>

</card>
</div>
<ng-template #logspin>
  <div >
    <mat-spinner
      style="margin: 0 auto"
      mode="indeterminate"
      [diameter]="200"
    ></mat-spinner>
  </div>
 </ng-template> 
</div>



