<section>
  <button class="btn btn-danger btn-sm float-md-right" (click)="onCancel()">X</button>
  <div class="row">
    <h3 class="text-center">Required Data Fields</h3>
    <div class="container-fluid">
      <div class="col-md-12">
        <div *ngFor="let tables of templateData">
          <div *ngIf="tables.analysis_type === 'ADA'">
            <div class="overflow-auto">
              <table class="table table-md-responsive table-bordered my-1">
                <thead class="thead-light">
                  <tr>
                    <th *ngFor="let data of tables.columns">{{ data.desc }}</th>
                  </tr>
                </thead>
                <tbody>
                  <td *ngFor="let description of tables.columns">{{ description.value }}</td>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>