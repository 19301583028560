import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../service/auth.service';
import { Router } from '@angular/router';
import { AuthNewService } from '../../../service/auth-new.service';


@Component({
    selector: 'app-header',
    templateUrl: 'component.html',
    // styleUrls: ['component.scss']
})
export class HeaderComponent implements OnInit {
    signedInUserName: string;
    githubImagePath: string;
    disableSignIn: boolean;
    disableHome: boolean;
    RedThreadLogoLink = 'https://www.google.com/';

    constructor(private authService: AuthNewService, private route: Router) {
    }

    ngOnInit() {
        this.authService.getCurrentUser((err, signedInUser) => {
            this.signedInUserName = signedInUser.username;
        });
        if (this.route.url === '/extra-layout/signin') {
            this.disableSignIn = true;
            this.disableHome = false;
        } else if(this.route.url === '/extra-layout/home') {
            this.disableSignIn = false;
            this.disableHome = true;
        }


    }
}
