<ng-template #illegal>
    <app-page403></app-page403>
</ng-template>

<card *ngIf="!illegalUser; else illegal">
    <div class="row">
        <div class="col-md-6"><strong>Analysis Type:</strong>
            {{getDisplayString(searchResult.analysisType)}} </div>
    </div>
    <div *ngIf="searchResult.analysisStatus === 'NewAnalysis' || searchResult.analysisStatus === 'TableResult'">

        <div>
            <div class="row">
                <div class="col-md-12"><strong>Analyte Name:</strong>
                    {{analysisDetails.analyteNames}}</div>
                <!-- <div class="col-md-12"><strong>Analysis Subtype:</strong>
                    {{getDisplayString(analysisDetails.analysisSubtype)}}</div> -->
                <div class="col-md-12" *ngIf="getDisplayString(analysisDetails.analysisSubtype) == 'Sensitivity'">
                    <strong>Screening Cutpoint: </strong> {{ analysisDetails.screening_cutpoint }}
                </div>
                <div class="col-md-12" *ngIf="getDisplayString(analysisDetails.analysisSubtype) == 'Sensitivity'">
                    <strong>Confirmatory Cutpoint: </strong> {{ analysisDetails.confirmatory_cutpoint }}
                </div>
                <div class="col-md-12">
                    <strong>Project Code:</strong> {{analysisDetails.projectCode_AP}}
                </div>
            </div>


            <!-- <div  *ngIf="analysisDetails.analysisSubtype === 'MV0'" class="col-md-6">
                        <strong>Project Code:</strong> {{analysisDetails.projectCodeMV}}
                    </div> -->
            <!-- <div  *ngIf="analysisDetails.analysisSubtype === 'SAM'" class="col-md-6">
                        <strong>Sample Analysis Project Code:</strong> {{analysisDetails.projectCodeSA}}
                        <strong>Method Validation Project Code:</strong> {{analysisDetails.projectCodeMV}}
                    </div> -->

        </div>

        <!-- <div [style.display]="tables.length > 0 ? 'inline' : 'none'"> -->
        <div *ngIf="tables.length > 0; else spin">
            <card [bgColor]="'info'" [color]="'info'" [outline]="true">
                <p><strong>Table Verification</strong> Please provide your input by clicking "Yes" or "No" button
                    for following tables. Yes if system identifies the table correctly No otherwise.</p>
            </card>
            <mat-accordion class="example-headers-align">
                <mat-expansion-panel *ngFor="let table of tables let i = index" [attr.data-index]="i"
                    [expanded]="step === i" (opened)="setStep(i)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p><strong>{{table.analysis_type}} - {{table.table_title}}</strong></p>
                        </mat-panel-title>
                        <mat-panel-description>
                            {{table.valid == true ? 'Yes' : (table.valid == false ? 'No' : '')}}</mat-panel-description>
                    </mat-expansion-panel-header>
                    <p><strong>Is this a {{table.table_type}} table?</strong></p>

                    <div [innerHTML]="table.rows" class="table-striped"></div>
                    <mat-action-row>
                        <button mat-raised-button color="warn" (click)="nextStep(table, false)">No</button>
                        <button mat-raised-button color="accent" (click)="nextStep(table, true)">Yes</button>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
            <div>
                <button class="btn btn-primary my-5" (click)="submitTableData()">Submit</button>
            </div>
        </div>
        <ng-template #spin>
            <div class="main-div">
                <h1>Processing</h1>
                <mat-spinner></mat-spinner>
            </div>

        </ng-template>
        <br>
        <!--<div *ngIf="tableSelected">-->

    </div>

    <div *ngIf="searchResult.analysisStatus === 'TableSelected' || searchResult.analysisStatus === 'Processed'">
        <div class="row">

            <div class="col-md-12"><strong>Analyte Name:</strong>
                {{analysisDetails.analyteNames}}</div>
            <!-- <div class="col-md-12"><strong>Analysis Subtype:</strong>
                {{getDisplayString(analysisDetails.analysisSubtype)}}</div> -->
            <div class="col-md-12" *ngIf="getDisplayString(analysisDetails.analysisSubtype) == 'Sensitivity'">
                <strong>Screening Cutpoint: </strong> {{ analysisDetails.screening_cutpoint }}
            </div>
            <div class="col-md-12" *ngIf="getDisplayString(analysisDetails.analysisSubtype) == 'Sensitivity'">
                <strong>Confirmatory Cutpoint: </strong> {{ analysisDetails.confirmatory_cutpoint}}
            </div>
            <div class="col-md-12">
                <strong>Project Code:</strong> {{analysisDetails.projectCode_AP}}
            </div>

        </div>
        <div class="main-div">
            <h3>Processing</h3>
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <!-- <div [style.display]="searchResult.analysisStatus === 'TableSelected' || searchResult.analysisStatus === 'Processed'? 'inline' : 'none'"></div> -->

    <div *ngIf="searchResult.analysisStatus === 'Complete'">
        <div class="row">

            <div class="col-md-12"><strong>Analyte Name:</strong>
                {{analysisDetails.analyteNames}}</div>
            <!-- <div class="col-md-12"><strong>Analysis Subtype:</strong>
                {{getDisplayString(analysisDetails.analysisSubtype)}}</div> -->
            <div class="col-md-12" *ngIf="getDisplayString(analysisDetails.analysisSubtype) == 'Sensitivity'">
                <strong>Screening Cutpoint: </strong> {{ analysisDetails.screening_cutpoint }}
            </div>
            <div class="col-md-12" *ngIf="getDisplayString(analysisDetails.analysisSubtype) == 'Sensitivity'">
                <strong>Confirmatory Cutpoint: </strong> {{ analysisDetails.confirmatory_cutpoint }}
            </div>
            <div class="col-md-12">
                <strong>Project Code:</strong> {{analysisDetails.projectCode_AP}}
            </div>

        </div>

        <div>
            <card [bgColor]="'success'" [color]="'Warn'" [outline]="true">
                <p><strong>Red Thread Analysis Result: </strong>Analysis results are grouped by sections found in the
                    report. In each section findings are categorised based in severity.</p>
                <p>
                    <badge [customColor]="'#EEC2B7'">Red</badge>
                    Validation : Failed
                </p>
                <p>
                    <badge [customColor]="'#F8F8A9'">Yellow</badge>
                    Validation : Review Requested
                </p>
                <p>
                    <badge [customColor]="'#C5EEB2'">Green</badge>
                    Validation : Passed
                </p>
            </card>
        </div>
        <div class = 'row'>
            <div *ngIf="['ADA', 'AP'].indexOf(formType) !== -1; else elseBlock">
                <div *ngIf="role !== 'RV'">

                <p class="ml-3"><strong>Analysis Report</strong></p>
                <a class = 'ml-4' target="_blank" href="{{searchResult.analysisResultData}}">
                    <file [type]="'xlsx'" [title]="'Data'"></file>
                </a>
                </div>
            </div>
        </div>
        <ng-template #elseBlock>
            <div>
                <p class="ml-3"><strong>Analysis Report</strong></p>
                <a target="_blank" href="{{searchResult.analysisResultFile}}" class="ml-3">
                    <file [type]="'pdf'" [title]="'Output'"></file>
                </a>
            </div>
            <div class="mx-5">
                <p class="ml-3"><strong>Analysis Report</strong></p>
                <a target="_blank" href="{{searchResult.AnalysisResultXlsxFile}}" class="ml-3">
                    <file [type]="'xlsx'" [title]="'Output'"></file>
                </a>
            </div>
        </ng-template>
        <div>
            <div><strong>Disclaimer</strong></div>
            <div style='font-size:80%;'>Red Thread is not a replacement for any quality control or quality
                assurance process. Ariadne does not guarantee the accuracy of the
                results of the software. To the fullest extent permitted under
                applicable law. Ariadne is not liable for any event or loss related
                to the reliance on Red Thread or the output produced by Red Thread.</div>

        </div>
        <br>
        <br>

        <mat-accordion class="example-headers-align">
            <mat-expansion-panel  class="my-3 bg-light" *ngFor="let result of results let i = index" [attr.data-index]="i"
                [expanded]="step === i" (opened)="setStep(i)" hideToggle>

                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <p><strong>{{result.heading}}</strong></p>
                    </mat-panel-title>
                    <div class="badge">
                        <mat-panel-description>
                            <badge [customColor]="'#C5EEB2'">Green {{result.green}}</badge>
                            <badge [customColor]="'#F8F8A9'">Yellow {{result.yellow}}</badge>
                            <badge [customColor]="'#EEC2B7'">Red {{result.red}}</badge>
                        </mat-panel-description>
                    </div>
                </mat-expansion-panel-header>
                <div class="mb-0" *ngIf="result.heading === 'GRAPHS'">
                    <div style="cursor: pointer;"  (click)="openGraph()">
                        <p class="shadow-lg p-3 mb-5 bg-white rounded text-primary">Click for Visualization</p>
                    </div>
                </div>
                <div *ngFor="let resultItem of result.resultItem">
                    <!-- this code is creating the templates. -->
                    <div *ngIf="result.heading !== 'GRAPHS'">
                        <p><strong><u>{{resultItem.tabletype}}</u></strong></p>
                    </div>
                    <div *ngFor="let item of resultItem.item" class="my-3">
                        <p *ngIf="item.color !== 'Graph'">
                            <strong>
                                <badge [customColor]="item.colorCode">
                                    {{item.color}}
                                </badge>
                            </strong>
                        </p>
                        <div *ngFor="let text of item.message">
                            <div *ngIf="item.color === 'Table' || item.color === 'Graph'; else elseBlock">
                                <div *ngIf="item.color === 'Table';">
                                    <div [innerHTML]="text" class="table table-striped my-5 mx-5"></div>
                                </div>
                            </div>
                            <ng-template #elseBlock>
                                <ul class="nolist">
                                    <li>{{ text }}</li>
                                </ul>
                            </ng-template>
                        </div>
                   
                    </div>
                
                </div>
        

            </mat-expansion-panel>
        </mat-accordion>
       
        <div class="row my-5"
            *ngIf="!reviewTableSelection && searchResult.tableReselection < 2 &&  ['ADA', 'AP'].indexOf(analysisDetails.analysisType) == -1">


            <div class="col-md-9">
                <p><strong>Table Reverification:</strong> To change your decision on table identification please Review
                    Tables following button.</p>
            </div>
            <div class="col-md-3">
                <button mat-raised-button color="accent" (click)="review()">Review Tables</button>
            </div>


        </div>
    </div>
    <br>
    <br>
    <div
        *ngIf="searchResult.analysisStatus === 'Complete' && reviewTableSelection && searchResult.tableReselection < 2">
        <h4>Tables for reselection </h4>
        <div *ngIf="tables.length > 0; else spin">
            <mat-accordion class="example-headers-align">
                <mat-expansion-panel *ngFor="let table of tables let i = index" [attr.data-index]="i"
                    [expanded]="step === i" (opened)="setStep(i)" hideToggle>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <p><strong>{{table.analysis_type}} - {{table.table_title}}</strong></p>
                        </mat-panel-title>
                        <mat-panel-description>
                            {{table.valid == true ? 'Yes' : (table.valid == false ? 'No' : '')}}</mat-panel-description>
                    </mat-expansion-panel-header>
                    <p><strong>Is this {{table.table_type}} table?</strong></p>

                    <div [innerHTML]="table.rows" class="table-striped"></div>
                    <mat-action-row>
                        <button mat-raised-button color="warn" (click)="nextStep(table, false)">No</button>
                        <button mat-raised-button color="accent" (click)="nextStep(table, true)">Yes</button>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <ng-template #spin>
            <div class="main-div">
                <h3>Processing</h3>
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>
        <br>
        <!--<div *ngIf="tableSelected">-->
        <div>
            <button mat-raised-button color="accent" (click)="submitTableData()">Submit Reselection</button>
        </div>
    </div>
    <div *ngIf="searchResult.analysisStatus === 'Error'">
        <div class="main-div">
            <app-page-500></app-page-500>
        </div>
    </div>

</card>
<ng-template #spin2>
    <div class="main-div">
        <h1>Processing</h1>
        <mat-spinner></mat-spinner>
    </div>

</ng-template>
