<card class="mb-0">

    <div class="container" *ngIf="!post; else forminfo">
        <form [formGroup]="analysisFormGroup" (ngSubmit)="onSubmit(analysisFormGroup.value)" novalidate>

            <div class="row">
                <div class="col-md-9 my-3">
                    <mat-form-field>
                        <mat-select placeholder="Analysis Type" formControlName="analysisType"
                            (ngModelChange)="resetAnalysis()" required disableOptionCentering>
                            <!-- <mat-option *ngFor="let obj of getDropDownValues('analysisType')" [value]="obj.name" [disabled]="obj.name !== 'SMR'"> -->
                            <!-- <mat-option *ngFor="let obj of getDropDownValues('analysisType')" [value]="obj.name" [disabled]="obj.name !== 'RSG'"> -->
                            <!-- <mat-option *ngFor="let obj of getDropDownValues('analysisType')" [value]="obj.name" [disabled]="obj.name !== 'ADA'"> -->
                            <!-- <mat-option *ngFor="let obj of getDropDownValues('analysisType')" [value]="obj.name" [disabled]="obj.name !== 'ADA' && obj.name !== 'LMR'"> -->
                            <!-- <mat-option *ngFor="let obj of getDropDownValues('analysisType')" [value]="obj.name" [disabled]="obj.name == 'RSG' || obj.name == 'CHR'"> -->
                            <mat-option *ngFor="let obj of getDropDownValues('analysisType')" [value]="obj.name">
                                {{obj.desc}}
                            </mat-option>

                            <!-- <ng-container *ngFor="let g of Groups">
                                <mat-option *ngFor="let obj of getDropDownValues('analysisType')" [value]="obj.name" [disabled]="obj.name != g">
                                    {{obj.desc}}
                                </mat-option>
                            </ng-container> -->
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div
                *ngIf="analysisFormGroup.value.analysisType === 'SMR' || analysisFormGroup.value.analysisType === 'LMR' ">
                <div class="row">
                    <div class="col-md-9 my-3">
                        <mat-form-field>
                            <mat-select placeholder="Analysis Subtype" formControlName="analysisSubtype" required
                                disableOptionCentering>
                                <mat-option *ngFor="let obj of getDropDownValues('analysisSubtype')" [value]="obj.name">
                                    {{obj.desc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!--                    <div class="col-md-6">
                        <mat-form-field>
                            <input matInput placeholder="Report Template"
                                   formControlName="report_template">
                        </mat-form-field>
                    </div>-->

                </div>
                <!-- <div class="row">
                    <div class="col-md-9 my-3">
                        <mat-form-field>
                            <mat-select placeholder="Template Name" formControlName="template" required disableOptionCentering>
                                <mat-option *ngFor="let obj of getDropDownValues('template')" [value]="obj.name">
                                    {{obj.desc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div> -->
                <div class="col-md-12">
                    <mat-radio-group style="margin-top: 10px;" aria-label="Select an option"
                        formControlName="multipleAnalytes">
                        <label>Multiple Analytes</label>
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button value="No" [disabled]="analyteNames.length > 1">No</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div formArrayName="analyteNames">
                    <div class="row" *ngFor="let analyte of analyteNames.controls; let i=index">
                        <div class="col-md-9 my-3">
                            <mat-form-field>
                                <input matInput placeholder="Analyte Name" type="text" [formControlName]="i" required>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3" *ngIf="i>0">
                            <button class="btn btn-danger" type="button" (click)="removeAnalyte(i)">Remove
                                Analyte</button>
                        </div>
                    </div>
                    <!-- <div *ngIf="analyteNames.at(0).value.toString().length > 2">
                        <button mat-raised-button type="button" (click)="addAnalyte()">Add Analyte</button>
                    </div> -->
                    <div *ngIf="analysisFormGroup.controls['multipleAnalytes'].value === 'Yes'">
                        <button mat-raised-button type="button" (click)="addAnalyte()">Add Analyte</button>
                    </div>
                </div>


                <!-- <div class *ngIf="analysisFormGroup.value.analysisSubtype === 'SA0'">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput placeholder="Sample Analysis Project Code"
                                    formControlName="projectCodeSA">
                                <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptionsSA | async" [value]="option">{{option}}</mat-option>
                        </mat-autocomplete>-->
                <!-- </mat-form-field>
                        </div>
                    </div>
                </div>  -->
                <div class="row">
                    <div class="col-md-9 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Project Code" formControlName="projectCodeSA" required
                                [ngClass]="{ 'is-invalid': analysisFormGroup.controls['projectCodeSA'].hasErrors }">
                            <mat-error *ngIf="analysisFormGroup.controls['projectCodeSA'].errors"
                                class="invalid-feedback error">
                                <mat-hint [hidden]="!analysisFormGroup.controls['projectCodeSA'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup.controls['projectCodeSA'].errors.pattern">
                                    Must be <strong>Alphanumeric</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <mat-select placeholder="Regression Model" formControlName="regressionModel"
                                disableOptionCentering>
                                <mat-option
                                    *ngFor="let obj of getDropDownValues('regressionModel_' + analysisFormGroup.value.analysisType)"
                                    [value]="obj.name">{{obj.desc}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Number of Freeze-Thaws Validated"
                                formControlName="freeze_thaw_dialog">
                            <mat-error>
                                <mat-hint
                                    [hidden]="!analysisFormGroup.controls['freeze_thaw_dialog'].hasError('maxlength')"
                                    align="end">Exceeds 3 character limit.</mat-hint>
                            </mat-error>

                        </mat-form-field>
                    </div>

                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Long-Term Stability (Days)" formControlName="lts80_dialog">
                        </mat-form-field>
                        <mat-error>
                            <mat-hint [hidden]="!analysisFormGroup.controls['lts80_dialog'].hasError('maxlength')"
                                align="end">Exceeds 10 character limit.</mat-hint>
                        </mat-error>
                    </div>
                    <!--                    <div class="col-md-6">
                        <mat-form-field>
                            <input matInput placeholder="LTS at -20C (Days)" formControlName="lts20_dialog">
                        </mat-form-field>
                    </div>-->
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Extraction Stability (Hours)"
                                formControlName="extraction_dialog">
                        </mat-form-field>
                        <mat-error>
                            <mat-hint [hidden]="!analysisFormGroup.controls['extraction_dialog'].hasError('maxlength')"
                                align="end">Exceeds 10 character limit.</mat-hint>
                        </mat-error>
                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Benchtop Stability (Hours)" formControlName="benchtop_dialog">
                        </mat-form-field>
                        <mat-error>
                            <mat-hint [hidden]="!analysisFormGroup.controls['benchtop_dialog'].hasError('maxlength')"
                                align="end">Exceeds 10 character limit.</mat-hint>
                        </mat-error>
                    </div>

                    <div class="col-md-6 my-3">
                        <!-- <mat-form-field>
                            <mat-select placeholder="Matrix" formControlName="sampleMatrix">
                                <mat-option *ngFor="let obj of getDropDownValues('sampleMatrix')" [value]="obj.name">
                                    {{obj.desc}}</mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <mat-form-field>
                            <input matInput placeholder="Matrix" formControlName="sampleMatrix"
                                [ngClass]="{ 'is-invalid': analysisFormGroup.controls['sampleMatrix'].errors }">
                            <mat-error *ngIf="analysisFormGroup.controls['sampleMatrix'].errors"
                                class="invalid-feedback error">
                                <mat-hint [hidden]="!analysisFormGroup.controls['sampleMatrix'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup.controls['sampleMatrix'].errors.pattern">
                                    Only <strong>Alphabets</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <!-- <mat-form-field>
                            <mat-select placeholder="Anti-Coagulant" formControlName="antiCoagulant">
                                <mat-option *ngFor="let obj of getDropDownValues('antiCoagulant')" [value]="obj.name">
                                    {{obj.desc}}</mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <mat-form-field>
                            <input matInput placeholder="Anti-Coagulant" formControlName="antiCoagulant"
                                [ngClass]="{ 'is-invalid': analysisFormGroup.controls['antiCoagulant'].errors }">
                            <mat-error *ngIf="analysisFormGroup.controls['antiCoagulant'].errors"
                                class="invalid-feedback error">
                                <mat-hint [hidden]="!analysisFormGroup.controls['antiCoagulant'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup.controls['antiCoagulant'].errors.pattern">
                                    Must be <strong>Alphanumeric</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <!-- <mat-form-field>
                            <mat-select placeholder="Species" formControlName="species">
                                <mat-option *ngFor="let obj of getDropDownValues('species')" [value]="obj.name">
                                    {{obj.desc}}</mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <mat-form-field>
                            <input matInput placeholder="Species" formControlName="species"
                                [ngClass]="{ 'is-invalid': analysisFormGroup.controls['species'].errors }">
                            <mat-error *ngIf="analysisFormGroup.controls['species'].errors"
                                class="invalid-feedback error">
                                <mat-hint [hidden]="!analysisFormGroup.controls['species'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup.controls['species'].errors.pattern">
                                    Only <strong>Alphabets</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <mat-select placeholder="Sample Preparation" formControlName="samplePrep"
                                disableOptionCentering>
                                <mat-option *ngFor="let obj of getDropDownValues('samplePrep')" [value]="obj.name">
                                    {{obj.desc}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker" [max]="maxDate" placeholder="Choose a date"
                                formControlName="sampleDate"
                                [ngClass]="{ 'is-invalid': analysisFormGroup.controls['sampleDate'].errors }">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>

                            <mat-error *ngIf="analysisFormGroup.controls['sampleDate'].errors"
                                class="invalid-feedback-error">
                                <div *ngIf="analysisFormGroup.controls['sampleDate'].errors.max">
                                    You cannot set <strong>Future Date</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div class *ngIf="analysisFormGroup.value.analysisSubtype === 'MV0'">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput placeholder="Method Validation Project Code"
                                    formControlName="projectCodeMV">
                                    <mat-autocomplete #auto1="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptionsMV | async" [value]="option">{{option}}</mat-option>
                                </mat-autocomplete>-->
                <!-- </mat-form-field>
                        </div>
                    </div>
                </div> -->
                <!-- <div *ngIf="analysisFormGroup.value.analysisSubtype === 'SAM'">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput placeholder="Sample Analysis Project Code"
                                    formControlName="projectCodeSA">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptionsSA | async" [value]="option">
                                        {{option}}</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput placeholder="Method Validation Project Code"
                                    formControlName="projectCodeMV">
                                <mat-autocomplete #auto1="matAutocomplete">
                                    <mat-option *ngFor="let option of filteredOptionsMV | async" [value]="option">
                                        {{option}}</mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                </div> -->

            </div>

            <div *ngIf="analysisFormGroup.value.analysisType === 'CHR'">
                <div formArrayName="analyteNames">
                    <div class="row" *ngFor="let analyte of analyteNames.controls; let i=index">
                        <div class="col-md-9">
                            <mat-form-field>
                                <input matInput placeholder="Study Name" type="text" [formControlName]="i" required>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-9">
                        <mat-form-field>
                            <mat-select placeholder="Regression Model" formControlName="regressionModel"
                                disableOptionCentering>
                                <mat-option
                                    *ngFor="let obj of getDropDownValues('regressionModel_' + analysisFormGroup.value.analysisType)"
                                    [value]="obj.name">
                                    {{obj.desc}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-3">

                    </div>

                    <div class="col-md-6">
                        <mat-form-field>
                            <input matInput placeholder="LLOQ" formControlName="lloq">
                        </mat-form-field>
                    </div>

                    <div class="col-md-6">
                        <mat-form-field>
                            <input matInput placeholder="ULOQ" formControlName="uloq">
                        </mat-form-field>
                    </div>

                    <div class="col-md-3">
                        <p>% RE</p>
                    </div>

                    <div class="col-md-3">
                        <mat-checkbox class="example-margin" formControlName="re_type">Default</mat-checkbox>
                    </div>
                    <div class="col-md-6">
                        <div *ngIf="!analysisFormGroup.value.re_type">
                            <mat-form-field>
                                <input matInput placeholder="% RE Value" formControlName="re_value">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <div
                *ngIf="analysisFormGroup.value.analysisType=='SMR' || analysisFormGroup.value.analysisType=='LMR' || analysisFormGroup.value.analysisType=='CHR'">
                <button class="btn btn-primary m-1 my-5" color="primary" type="submit"
                    [disabled]="((!analysisFormGroup.valid) || (analysisFormGroup.controls['multipleAnalytes'].value === 'Yes' && analysisFormGroup.controls['analyteNames'].value.length == 1))">Submit
                    Form
                </button>

            </div>
        </form>

        <form [formGroup]="analysisFormGroup_AP" (ngSubmit)="onSubmit(analysisFormGroup_AP.value)" novalidate>
            <div *ngIf="analysisFormGroup.value.analysisType === 'AP'">
                <div formArrayName="analyteNames">
                    <div class="row" *ngFor="let analyte of analyteNames.controls; let i=index">
                        <div class="col-md-9 my-3">
                            <mat-form-field>
                                <input matInput placeholder="Analyte Name" type="text" [formControlName]="i" required
                                   >
                            </mat-form-field>
                            <ng-container
                            *ngIf="analyteNames.controls[i]?.invalid && (analyteNames.controls[i]?.dirty || analyteNames.controls[i]?.touched)">
                            <span class="text-danger">Must be
                                <strong>Alphanumeric</strong></span>
                        </ng-container>
                        </div>
                        <div class="col-md-3" *ngIf="i>0">
                            <button class="btn btn-danger" type="button" (click)="removeAnalyte(i)">Remove
                                Analyte</button>
                        </div>
                    </div>
                    <div *ngIf="analyteNames.at(0).value.toString().length > 2">
                        <button mat-raised-button type="button" (click)="addAnalyte()">Add Analyte</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Project Code" formControlName="projectCode_AP" required
                                [ngClass]="{ 'is-invalid': formControlsAP.projectCode_AP.errors }">
                            <!--<mat-autocomplete #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptionsSA | async" [value]="option">{{option}}</mat-option>
                                                    </mat-autocomplete>-->
                            <mat-error *ngIf="analysisFormGroup_AP.controls['projectCode_AP'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_AP.controls['projectCode_AP'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_AP.controls['projectCode_AP'].errors.pattern">Must be
                                    <strong>Alphanumeric</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <button *ngIf="analysisFormGroup.value.analysisType=='AP'" class="btn btn-primary m-1 my-5"
                    color="primary" type="submit" [disabled]="!analysisFormGroup_AP.valid">
                    Submit
                </button>
            </div>
        </form>

        <form [formGroup]="analysisFormGroup_RSG" (ngSubmit)="onSubmit(analysisFormGroup_RSG.value)" novalidate>
            <div *ngIf="analysisFormGroup.value.analysisType === 'RSG'">
                <div class="row">
                    <div class="col-md-9 my-3">
                        <mat-form-field>
                            <mat-select placeholder="Analysis Subtype" formControlName="analysisSubtype" required
                                disableOptionCentering>
                                <mat-option
                                    *ngFor="let obj of getDropDownValues('analysisSubtype_' + analysisFormGroup.value.analysisType)"
                                    [value]="obj.name">
                                    {{obj.desc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-9 my-3">
                        <mat-form-field>
                            <mat-select placeholder="Report Template" formControlName="report_template" required
                                disableOptionCentering>
                                <mat-option *ngFor="let obj of getDropDownValues('report_Template')" [value]="obj.name">
                                    {{obj.desc}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!--                    <div class="col-md-6">
                            <mat-form-field>
                                <input matInput placeholder="Report Template"
                                       formControlName="report_template">
                            </mat-form-field>
                        </div>-->

                </div>
                <div formArrayName="analyteNames">
                    <div class="row" *ngFor="let analyte of analyteNames.controls; let i=index">
                        <div class="col-md-9 my-3">
                            <mat-form-field>
                                <input matInput placeholder="Analyte Name" type="text" [formControlName]="i" required>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3" *ngIf="i>0">
                            <button class="btn btn-danger" type="button" (click)="removeAnalyte(i)">Remove
                                Analyte</button>
                        </div>
                    </div>
                    <div *ngIf="analyteNames.at(0).value.toString().length > 2">
                        <button mat-raised-button type="button" (click)="addAnalyte()">Add Analyte</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Project Code" formControlName="projectCodeSA" required
                                [ngClass]="{ 'is-invalid': formControls.projectCodeSA.errors }">
                            <!--<mat-autocomplete #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredOptionsSA | async" [value]="option">{{option}}</mat-option>
                                                    </mat-autocomplete>-->
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['projectCodeSA'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['projectCodeSA'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['projectCodeSA'].errors.pattern">Must be
                                    <strong>Alphanumeric</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div *ngIf="analysisFormGroup_RSG.value.report_template === 'SA'" class="col-md-9 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Sponsor" formControlName="sponsor_name" required
                                [ngClass]="{ 'is-invalid': formControls.sponsor_name?.errors }">
                            <!-- <mat-error *ngIf="analysisFormGroup_RSG.controls['sponsor_name'].errors"
                                class="invalid-feedback error">
                                <div *ngIf="analysisFormGroup_RSG.controls['sponsor_name'].errors.required">Must be
                                    <strong>Alphanumeric</strong>
                                </div>
                            </mat-error> -->
                        </mat-form-field>

                    </div>

                    <div *ngIf="analysisFormGroup_RSG.value.report_template === 'SA'" class="col-md-9 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Validation Project Code" formControlName="val_project_code"
                                required [ngClass]="{ 'is-invalid': formControls.val_project_code?.errors }">

                            <mat-error *ngIf="analysisFormGroup_RSG.controls['val_project_code'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['val_project_code'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['val_project_code'].errors.pattern">Must be
                                    <strong>Alphanumeric</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <mat-select placeholder="Regression Model" formControlName="regressionModel"
                                disableOptionCentering>
                                <mat-option
                                    *ngFor="let obj of getDropDownValues('regressionModel_' + analysisFormGroup.value.analysisType)"
                                    [value]="obj.name">
                                    {{obj.desc}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6 my-3">
                        <!-- <mat-form-field>
                            <mat-select placeholder="Anti-Coagulant" formControlName="antiCoagulant">
                                <mat-option *ngFor="let obj of getDropDownValues('antiCoagulant')" [value]="obj.name">
                                    {{obj.desc}}</mat-option>
                            </mat-select>
                        </mat-form-field> -->
                        <mat-form-field>
                            <input matInput placeholder="Anti-Coagulant" formControlName="antiCoagulant"
                                [ngClass]="{ 'is-invalid': formControls.antiCoagulant.errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['antiCoagulant'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['antiCoagulant'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['antiCoagulant'].errors.pattern">
                                    Must be <strong>Alphanumeric</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Weighting Factor" formControlName="weighting_factor"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['weighting_factor'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['weighting_factor'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['weighting_factor'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['weighting_factor'].errors.pattern">
                                    Must be <strong>Alphanumeric</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Enter Report ID" formControlName="report_id"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['report_id'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['report_id'].errors"
                                class="invalid-feedback error">
                                <mat-hint [hidden]="!analysisFormGroup_RSG.controls['report_id'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['report_id'].errors.pattern">
                                    Must be <strong>Alphanumeric</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Enter Method ID" formControlName="method_id"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['method_id'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['method_id'].errors"
                                class="invalid-feedback error">
                                <mat-hint [hidden]="!analysisFormGroup_RSG.controls['method_id'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['method_id'].errors.pattern">
                                    Must be <strong>Alphanumeric</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'" class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Concomitant Name" formControlName="concomitant_name"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['concomitant_name'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['concomitant_name'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['concomitant_name'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['concomitant_name'].errors.pattern">
                                    Must be <strong>Alphanumeric</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Enter Bench-top Temperature Condition"
                                formControlName="benchTop_Temp"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['benchTop_Temp'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['benchTop_Temp'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['benchTop_temp'].hasError('maxlength')"
                                    align="end">Exceeds 100 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['benchTop_Temp'].errors.pattern">
                                    Must be <strong>Alphanumeric</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'" class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Enter comed conc." formControlName="comed_conc"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['comed_conc'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['comed_conc'].errors"
                                class="invalid-feedback error">
                                <mat-hint [hidden]="!analysisFormGroup_RSG.controls['comed_conc'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['comed_conc'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Enter # of Cycles" formControlName="noOfcycles"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['noOfcycles'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['noOfcycles'].errors"
                                class="invalid-feedback error">
                                <mat-hint [hidden]="!analysisFormGroup_RSG.controls['noOfcycles'].hasError('maxlength')"
                                    align="end">Exceeds 3 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['noOfcycles'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Enter # of Bench-top hours" formControlName="benchtop_hours"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['benchtop_hours'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['benchtop_hours'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['benchtop_hours'].hasError('maxlength')"
                                    align="end">Exceeds 10 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['benchtop_hours'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Enter Frozen Temperature" formControlName="frozen_temp"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['frozen_temp'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['frozen_temp'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['frozen_temp'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['frozen_temp'].errors.pattern">
                                    Must be a valid format.
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Enter long-term frozen temperature"
                                formControlName="longTerm_frozen_temp"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['longTerm_frozen_temp'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['longTerm_frozen_temp'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['longTerm_frozen_temp'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['longTerm_frozen_temp'].errors.pattern">
                                    Must be a valid format.
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>



                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Enter # of long-term storage days"
                                formControlName="longTerm_storage_days"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['longTerm_storage_days'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['longTerm_storage_days'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['longTerm_storage_days'].hasError('maxlength')"
                                    align="end">Exceeds 10 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['longTerm_storage_days'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Enter Protocol ID" formControlName="protocol_id"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['protocol_id'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['protocol_id'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['protocol_id'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['protocol_id'].errors.pattern">
                                    Must be a valid format.
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Enter thawing temp" formControlName="thawing_temp"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['thawing_temp'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['thawing_temp'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['thawing_temp'].hasError('maxlength')"
                                    align="end">Exceeds 100 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['thawing_temp'].errors.pattern">
                                    Must be a valid format.
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'" class="col-md-12 my-3">
                        <div formArrayName="STD_conc_arr">
                            <div class="row">
                                <div class="col-md-4 my-3" *ngFor="let conc of STD_conc_arr.controls; let i=index">
                                    <mat-form-field class="d-xl-inline-block">
                                        <input matInput placeholder="STD Conc {{ i + 1 }}" type="text"
                                            [formControlName]="i">
                                    </mat-form-field>
                                    <div class=" ml-3 d-xl-inline-block" *ngIf="i>0">
                                        <button class="btn btn-danger btn-sm" type="button"
                                            (click)="removeAnalyte_RSG(i)">X</button>
                                    </div>
                                    <ng-container
                                        *ngIf="STD_conc_arr.controls[i].invalid && (STD_conc_arr.controls[i].dirty || STD_conc_arr.controls[i].touched)">
                                        <p class="text-danger">STD Concentration must be numeric.</p>
                                    </ng-container>
                                </div>
                            </div>
                            <div *ngIf="STD_conc_arr.at(0).value.toString().length > 0">
                                <button mat-raised-button type="button" (click)="addAnalyte_RSG()">Add STD conc</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Conc unit" formControlName="conc_unit"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['conc_unit'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['conc_unit'].errors"
                                class="invalid-feedback error">
                                <mat-hint [hidden]="!analysisFormGroup_RSG.controls['conc_unit'].hasError('maxlength')"
                                    align="end">Exceeds 10 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['conc_unit'].errors.pattern">
                                    <strong>Any</strong> character
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'" class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Dilution QC conc" formControlName="Dilution_QC_conc"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['Dilution_QC_conc'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['Dilution_QC_conc'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['Dilution_QC_conc'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['Dilution_QC_conc'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'" class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Low QC conc" formControlName="Low_QC_conc"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['Low_QC_conc'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['Low_QC_conc'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['Low_QC_conc'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['Low_QC_conc'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'" class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Mid QC conc" formControlName="Mid_QC_conc"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['Mid_QC_conc'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['Mid_QC_conc'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['Mid_QC_conc'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['Mid_QC_conc'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'" class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="High QC conc" formControlName="High_QC_conc"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['High_QC_conc'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['High_QC_conc'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['High_QC_conc'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['High_QC_conc'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div *ngIf="analysisFormGroup_RSG.value.report_template === 'SA'" class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Study Sample Storage Condition" formControlName="sample_temp"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['sample_temp'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['sample_temp'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['sample_temp'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['sample_temp'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'" class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Anchor point 1" formControlName="anchor_point_1"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['anchor_point_1'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['anchor_point_1'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['anchor_point_1'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['anchor_point_1'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'" class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Anchor point 2" formControlName="anchor_point_2"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['anchor_point_2'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['anchor_point_2'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['anchor_point_2'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['anchor_point_2'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Method Type" formControlName="method_type"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['method_type'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['method_type'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['method_type'].hasError('maxlength')"
                                    align="end">Exceeds 100 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['method_type'].errors.pattern">
                                    Must be a valid format.
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div *ngIf="analysisFormGroup_RSG.value.report_template === 'SA'" class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="MRD" formControlName="MRD"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['MRD'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['MRD'].errors"
                                class="invalid-feedback error">
                                <mat-hint [hidden]="!analysisFormGroup_RSG.controls['MRD'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['MRD'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>

                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="LLOQ" formControlName="lloq"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['lloq'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['lloq'].errors"
                                class="invalid-feedback error">
                                <mat-hint [hidden]="!analysisFormGroup_RSG.controls['lloq'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['lloq'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="ULOQ" formControlName="uloq"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['uloq'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['uloq'].errors"
                                class="invalid-feedback error">
                                <mat-hint [hidden]="!analysisFormGroup_RSG.controls['uloq'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['uloq'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'" class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Sample volume" formControlName="sample_volume"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['sample_volume'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['sample_volume'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['sample_volume'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['sample_volume'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Matrix" formControlName="sampleMatrix"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['sampleMatrix'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['sampleMatrix'].errors"
                                class="invalid-feedback error">
                                <mat-hint
                                    [hidden]="!analysisFormGroup_RSG.controls['sampleMatrix'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['sampleMatrix'].errors.pattern">
                                    Only <strong>Alphabets</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">

                        <mat-form-field>
                            <input matInput placeholder="Species" formControlName="species"
                                [ngClass]="{ 'is-invalid': analysisFormGroup_RSG.controls['species'].errors }">
                            <mat-error *ngIf="analysisFormGroup_RSG.controls['species'].errors"
                                class="invalid-feedback error">
                                <mat-hint [hidden]="!analysisFormGroup_RSG.controls['species'].hasError('maxlength')"
                                    align="end">Exceeds 50 character limit.</mat-hint>
                                <div *ngIf="analysisFormGroup_RSG.controls['species'].errors.pattern">
                                    Only <strong>Alphabets</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div *ngIf="analysisFormGroup_RSG.value.report_template === 'SA'" class="col-md-6 my-3">
                        <mat-form-field>
                            <input matInput [matDatepicker]="picker" [max]="maxDate" placeholder="Collection Date"
                                formControlName="collection_date">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div *ngIf="analysisFormGroup.value.analysisType=='RSG'" class="my-5">
                <button class="btn btn-primary m-1" color="primary" type="submit"
                    [disabled]="(!analysisFormGroup_RSG.valid)">Submit Form
                </button>
            </div>
        </form>


        <form [formGroup]="analysisFormGroup_ADA" (ngSubmit)="onSubmit(analysisFormGroup_ADA.value)" novalidate>
            <div *ngIf="analysisFormGroup.value.analysisType == 'ADA'">
                <!-- <div class="row">
                        <div class="col-md-9">
                            <mat-form-field>
                                <mat-select placeholder="Analysis Type" formControlName="analysisType"
                                    (ngModelChange)="resetAnalysis(analysisFormGroup_ADA)" required>
                                    <mat-option *ngFor="let obj of getDropDownValues('analysisType')" [value]="obj.name">
                                        {{obj.desc}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div> -->
                <div class="row">
                    <div class="col-md-9 my-3">
                        <mat-form-field>
                            <mat-select #val placeholder="Analysis Subtype" formControlName="analysisSubtype" required
                                disableOptionCentering>
                                <mat-option *ngFor="let obj of getDropDownValues('analysisSubtype_ADA')"
                                    [value]="obj.name">
                                    {{obj.desc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row" *ngIf="analysisFormGroup_ADA.value.analysisSubtype =='Sensitivity'">
                    <div class="col-md-9">
                        <mat-form-field>
                            <mat-select placeholder="Sensitivity type" formControlName="ada_subtype" required
                                disableOptionCentering>
                                <mat-option *ngFor="let obj of getDropDownValues('ada_subtype')" [value]="obj.name">
                                    {{obj.desc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="analysisFormGroup_ADA.value.analysisSubtype =='Cut Point'">
                    <div class="col-md-9">
                        <mat-form-field>
                            <mat-select placeholder="Cut Point type" formControlName="ada_subtype" required
                                disableOptionCentering>
                                <mat-option *ngFor="let obj of getDropDownValues('ada_subtype')" [value]="obj.name">
                                    {{obj.desc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="analysisFormGroup_ADA.value.analysisSubtype =='Cut Point & Sensitivity'">
                    <div class="col-md-9">
                        <mat-form-field>
                            <mat-select placeholder="Cut Point & Sensitivity type" formControlName="ada_subtype"
                                required disableOptionCentering>
                                <mat-option *ngFor="let obj of getDropDownValues('ada_subtype')" [value]="obj.name">
                                    {{obj.desc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row"
                    *ngIf="analysisFormGroup_ADA.value.analysisSubtype =='Sensitivity' && analysisFormGroup_ADA.value.ada_subtype == 'Screening'">
                    <div class="col-md-9">
                        <mat-form-field>
                            <input matInput placeholder="Screening Cut Point" formControlName="screening_cutpoint"
                                required
                                [ngClass]="{ 'is-invalid': analysisFormGroup_ADA.controls['screening_cutpoint'].hasErrors }">
                            <mat-error *ngIf="analysisFormGroup_ADA.controls['screening_cutpoint'].errors"
                                class="invalid-feedback error">
                                <div *ngIf="analysisFormGroup_ADA.controls['screening_cutpoint'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row"
                    *ngIf="analysisFormGroup_ADA.value.analysisSubtype =='Sensitivity' && analysisFormGroup_ADA.value.ada_subtype == 'Confirmatory'">
                    <div class="col-md-9">
                        <mat-form-field>
                            <input matInput placeholder="Confirmatory Cut Point" type="text"
                                formControlName="confirmatory_cutpoint" required>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row"
                    *ngIf="analysisFormGroup_ADA.value.analysisSubtype =='Sensitivity' && analysisFormGroup_ADA.value.ada_subtype == 'Screening & Confirmatory'">
                    <div class="col-md-6">
                        <mat-form-field>
                            <input matInput placeholder="Screening Cut Point" formControlName="screening_cutpoint"
                                required
                                [ngClass]="{ 'is-invalid': analysisFormGroup_ADA.controls['screening_cutpoint'].hasErrors }">
                            <mat-error *ngIf="analysisFormGroup_ADA.controls['screening_cutpoint'].errors"
                                class="invalid-feedback error">
                                <div *ngIf="analysisFormGroup_ADA.controls['screening_cutpoint'].errors.pattern">
                                    <strong>Numeric</strong> only
                                </div>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field>
                            <input matInput placeholder="Confirmatory Cut Point" type="text"
                                formControlName="confirmatory_cutpoint" required>
                        </mat-form-field>
                    </div>
                </div>

                <div formArrayName="analyteNames">
                    <div class="row" *ngFor="let analyte of analyteNames.controls; let i=index">
                        <div class="col-md-9 my-3">
                            <mat-form-field>
                                <input matInput placeholder="Analyte Name" type="text" [formControlName]="i" required>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3" *ngIf="i>0">
                            <button class="btn btn-danger" type="button" (click)="removeAnalyte(i)">Remove
                                Analyte</button>
                        </div>
                    </div>
                    <div *ngIf="analyteNames.at(0).value.toString().length > 2">
                        <button mat-raised-button type="button" (click)="addAnalyte()">Add Analyte</button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-9 my-3">
                        <mat-form-field>
                            <input matInput placeholder="Project Code" formControlName="projectCodeSA" required
                                [ngClass]="{ 'is-invalid': analysisFormGroup_ADA.controls['projectCodeSA'].hasErrors }">
                            <mat-error *ngIf="analysisFormGroup_ADA.controls['projectCodeSA'].errors"
                                class="invalid-feedback error">
                                <div *ngIf="analysisFormGroup_ADA.controls['projectCodeSA'].errors.pattern">
                                    Must be <strong>Alphanumeric</strong>
                                </div>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <mat-chip-list #taglist>
                                <mat-chip selected color="primary"
                                    *ngFor="let Tag of formControls1.tukeyCoefficient.value; let i = index;"
                                    [selectable]="selectable" [removable]="removable" (removed)="remove(i)">
                                    {{Tag}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="Tukey Coefficient(1.5,2,2.5,3)" [matChipInputFor]="taglist"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)"
                                    formArrayName="tukeyCoefficient" />
                            </mat-chip-list>
                        </mat-form-field>
                    </div>
                    <!-- <div class="col-md-12">
                        <br />
                        <mat-radio-group aria-label="Select an option" formControlName="negativeControlValues">
                            &nbsp;&nbsp;&nbsp;
                            <label>Are the Negative Control Values included?</label>
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                            <mat-radio-button value="No">No</mat-radio-button>
                        </mat-radio-group>
                    </div>-->
                </div>
                <div class="row">
                    <div class="col-md-6 my-3">
                        <mat-form-field>
                            <mat-select #val placeholder="Concentration Unit" formControlName="concentrationUnit"
                                required disableOptionCentering>
                                <mat-option *ngFor="let obj of getDropDownValues('concentrationUnit')"
                                    [value]="obj.name">
                                    {{obj.desc}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-9">
                        <mat-accordion class="example-headers-align">
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    Advanced Options
                                </mat-expansion-panel-header>
                                <mat-accordion>
                                    <mat-expansion-panel
                                        *ngIf="analysisFormGroup_ADA.value.analysisSubtype =='Sensitivity' || analysisFormGroup_ADA.value.analysisSubtype =='Cut Point & Sensitivity'">
                                        <mat-expansion-panel-header>
                                            Regression Type
                                        </mat-expansion-panel-header>
                                        <mat-radio-group aria-label="Select an option" formControlName="PL">
                                            <mat-radio-button value="4pl" class="mb-2">4 PL</mat-radio-button><br>
                                            <mat-radio-button value="5pl" class="my-3">5 PL</mat-radio-button><br>
                                            <mat-radio-button value="4pl+5pl" class="my-3">4 PL + 5
                                                PL</mat-radio-button>
                                        </mat-radio-group>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel class="my-3">
                                        <mat-expansion-panel-header>
                                            NAB Dataset
                                        </mat-expansion-panel-header>
                                        <mat-radio-group aria-label="Select an option" formControlName="nabDataset">
                                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                                            <mat-radio-button value="No" class="mx-5">No</mat-radio-button>
                                        </mat-radio-group>
                                    </mat-expansion-panel>

                                    <mat-expansion-panel class="my-3">
                                        <mat-expansion-panel-header>
                                            % CV Threshold for Replicates
                                        </mat-expansion-panel-header>
                                        <mat-radio-group aria-label="Select an option" formControlName="CVThreshold">
                                            <mat-radio-button value="20" class="mb-2">20</mat-radio-button><br>
                                            <mat-radio-button value="25" class="my-3">25</mat-radio-button><br>
                                            <mat-radio-button value="30" class="my-3">30</mat-radio-button><br>
                                            <mat-radio-button value="none" class="my-3">None</mat-radio-button>
                                        </mat-radio-group>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>

            <div *ngIf="analysisFormGroup.value.analysisType=='ADA'" class="my-5">
                <button class="btn btn-primary m-1" color="primary" type="submit"
                    [disabled]="(!analysisFormGroup_ADA.valid)">Submit Form
                </button>
            </div>
        </form>


        <br>
        <br><br>

    </div>

    <ng-template #forminfo>
        <div class="row">
            <div class="col-md-6"><strong>Analysis Type:</strong>
                {{getDisplayString(analysisDetails.analysisType)}} </div>

        </div>
        <br>
        <div *ngIf="analysisDetails.analysisType=='SMR' || analysisDetails.analysisType == 'LMR'">

            <!-- <div class *ngIf="analysisDetails.analysisSubtype === 'SA0'" class="row"> -->
            <div class="row">
                <div class="col-md-6"><strong>Project Code:</strong> {{analysisDetails.projectCodeSA}}
                </div>
            </div>
            <!-- </div> -->
            <!-- <div class *ngIf="analysisDetails.analysisSubtype === 'MV0'" class="row">
                <div class="col-md-6"><strong>Project Code:</strong> {{analysisDetails.projectCodeMV}}
                </div>
            </div> -->
            <div class *ngIf="analysisDetails.analysisSubtype === 'SAM'" class="row">
                <div class="col-md-6"><strong>Sample Analysis Project Code:</strong> {{analysisDetails.projectCodeSA}}
                </div>
                <div class="col-md-6"><strong>Method Validation Project Code:</strong> {{analysisDetails.projectCodeMV}}
                </div>
            </div>

            <div class="row">
                <div class="col-md-6"><strong>Analyte Name: </strong>{{analysisDetails.analyteNames}}</div>
                <div class="col-md-6"></div>
                <div class="col-md-6"><strong>Analysis Subtype:</strong>
                    {{getDisplayString(analysisDetails.analysisSubtype)}}</div>
                <div class="col-md-6"><strong>Regression Model:</strong>
                    {{getDisplayString(analysisDetails.regressionModel)}}</div>
                <div class="col-md-6"><strong>Long-Term Stability (Days):</strong> {{analysisDetails.lts80_dialog}}
                </div>
                <!--            <div class="col-md-6"><strong>LTS at -20C (Days):</strong> {{analysisDetails.lts20_dialog}}</div>-->
                <div class="col-md-6"><strong>Extraction Stability (Hours):</strong>
                    {{analysisDetails.extraction_dialog}}</div>
                <div class="col-md-6"><strong>Benchtop Stability (Hours):</strong> {{analysisDetails.benchtop_dialog}}
                </div>
                <div class="col-md-6"><strong>Number of Freeze-Thaws Validated:</strong>
                    {{analysisDetails.freeze_thaw_dialog}}</div>
                <div class="col-md-6"><strong>Matrix:</strong> {{analysisDetails.sampleMatrix}}</div>
                <div class="col-md-6"><strong>Anti Coagulant:</strong>
                    {{ analysisDetails.antiCoagulant}}</div>
                <div class="col-md-6"><strong>Species:</strong> {{analysisDetails.species}}</div>
                <div class="col-md-6"><strong>Date:</strong> {{ analysisDetails.sampleDate | date: 'shortDate' }}</div>
                <div class="col-md-6"><strong>Sample Preparation:</strong>
                    {{getDisplayString( analysisDetails.samplePrep)}}</div>
                <div class="col-md-6">
                    <strong class="d-inline-block">Multiple Analytes(Yes/No): </strong>
                    <p class="d-sm-inline-block mx-2" *ngIf="analysisDetails.multipleAnalytes === 'True'">Yes</p>
                    <p class="d-sm-inline-block mx-2" *ngIf="analysisDetails.multipleAnalytes === 'False'">No</p>

                </div>
                <!-- {{ analysisDetails.multipleAnalytes }} -->
            </div>

            <div *ngIf="analysisDetails.analysisType=='CHR'">
                <div class="row">
                    <div class="col-md-6"><strong>Study Name: </strong>{{analysisDetails.analyteNames}}</div>
                    <div class="col-md-6"><strong>Regression Model:</strong>
                        {{getDisplayString(analysisDetails.regressionModel)}}</div>
                    <div class="col-md-6"><strong>LLOQ:</strong> {{analysisDetails.lloq}}</div>
                    <div class="col-md-6"><strong>ULOQ:</strong> {{analysisDetails.uloq}}</div>
                </div>
            </div>
        </div>



        <div *ngIf="analysisDetails.analysisType=='RSG'">
            <div class="row">
                <div class="col-md-6"><strong>Analyte Name: </strong>{{analysisDetails.analyteNames}}</div>
                <div class="col-md-6"><strong>Project Code:</strong> {{analysisDetails.projectCodeSA}}
                </div>
                <div class="col-md-6"><strong>Analysis Subtype:</strong>
                    {{getDisplayString(analysisDetails.analysisSubtype)}}</div>
                <div class="col-md-6"><strong>Report Template:</strong>
                    {{getDisplayString(analysisDetails.report_template)}}</div>
                <div class="col-md-6"><strong>Regression Model:</strong>
                    {{getDisplayString(analysisDetails.regressionModel)}}</div>
                <div class="col-md-6"><strong>Anti-Coagulant:</strong>
                    {{analysisDetails.antiCoagulant}}</div>
                <div class="col-md-6"><strong>Weighting Factor: </strong>{{analysisDetails.weighting_factor}}</div>
                <div class="col-md-6"><strong>Report ID: </strong>{{analysisDetails.report_id}}</div>
                <div class="col-md-6"><strong>Method ID: </strong>{{analysisDetails.method_id}}</div>
                <div class="col-md-6"><strong>Method Type:</strong> {{analysisDetails.method_type}}</div>
                <div class="col-md-6"><strong>Bench-top Temperature Condition:
                    </strong>{{analysisDetails.benchTop_Temp}}</div>
                <div class="col-md-6" *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'"><strong>comed conc.:
                    </strong>{{analysisDetails.comed_conc}}</div>
                <div class="col-md-6"><strong>Concentration Unit: </strong>{{analysisDetails.conc_unit}}</div>
                <div class="col-md-6"><strong># of Cycles: </strong>{{analysisDetails.noOfcycles}}</div>
                <div class="col-md-6"><strong># of Bench-top hours: </strong>{{analysisDetails.benchtop_hours}}</div>
                <div class="col-md-6"><strong>Frozen Temperature: </strong>{{analysisDetails.frozen_temp}}</div>
                <div class="col-md-6"><strong>Long-term frozen temperature:
                    </strong>{{analysisDetails.longTerm_frozen_temp}}</div>
                <div class="col-md-6"><strong># of long-term storage days:
                    </strong>{{analysisDetails.longTerm_storage_days}}</div>
                <div class="col-md-6"><strong>Protocol ID: </strong>{{analysisDetails.protocol_id}}</div>
                <div class="col-md-6"><strong>Thawing temp: </strong>{{analysisDetails.thawing_temp}}</div>
                <div class="col-md-6" *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'"><strong>STD Conc:
                    </strong>{{analysisDetails.STD_conc_arr}}</div>
                <div *ngIf="analysisFormGroup_RSG.value.report_template === 'SA'" class="col-md-6"><strong>Sponsor:
                    </strong>{{analysisDetails.sponsor_name}}</div>
                <div *ngIf="analysisFormGroup_RSG.value.report_template === 'SA'" class="col-md-6"><strong>Validation
                        Project Code: </strong>{{analysisDetails.val_project_code}}</div>
                <div *ngIf="analysisFormGroup_RSG.value.report_template === 'SA'" class="col-md-6"><strong>Study Sample
                        Storage Condition: </strong>{{analysisDetails.sample_temp}}</div>
                <div *ngIf="analysisFormGroup_RSG.value.report_template === 'SA'" class="col-md-6"><strong>Collection
                        Date: </strong>{{analysisDetails.collection_date | date: 'shortDate'}}</div>
                <div *ngIf="analysisFormGroup_RSG.value.report_template === 'SA'" class="col-md-6"><strong>MRD:
                    </strong>{{analysisDetails.MRD}}</div>
                <div class="col-md-6" *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'"><strong>Dilution QC
                        Conc: </strong>{{analysisDetails.Dilution_QC_conc}}</div>
                <div class="col-md-6" *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'"><strong>Low QC Conc:
                    </strong>{{analysisDetails.Low_QC_conc}}</div>
                <div class="col-md-6" *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'"><strong>Mid QC Conc:
                    </strong>{{analysisDetails.Mid_QC_conc}}</div>
                <div class="col-md-6" *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'"><strong>High QC Conc:
                    </strong>{{analysisDetails.High_QC_conc}}</div>
                <div class="col-md-6" *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'"><strong>Anchor point
                        1: </strong>{{analysisDetails.anchor_point_1}}</div>
                <div class="col-md-6" *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'"><strong>Anchor point
                        2: </strong>{{analysisDetails.anchor_point_2}}</div>
                <div class="col-md-6"><strong>LLOQ:</strong> {{analysisDetails.lloq}}</div>
                <div class="col-md-6"><strong>ULOQ:</strong> {{analysisDetails.uloq}}</div>
                <div class="col-md-6" *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'"><strong>Sample
                        Volume:</strong> {{analysisDetails.sample_volume}}</div>
                <div class="col-md-6" *ngIf="analysisFormGroup_RSG.value.report_template !== 'SA'"><strong>Concomitant
                        Name:</strong> {{analysisDetails.concomitant_name}}</div>
                <div class="col-md-6"><strong>Matrix:</strong> {{analysisDetails.sampleMatrix}}</div>
                <div class="col-md-6"><strong>Species:</strong> {{analysisDetails.species}}</div>
            </div>
        </div>

        <div *ngIf="analysisDetails.analysisType=='ADA'">
            <div class="row">

                <div class="col-md-6"><strong>Analyte Name: </strong>{{analysisDetails.analyteNames}}</div>
                <div class="col-md-6"><strong>Analyte Subtype: </strong>{{analysisDetails.analysisSubtype}}</div>
                <div class="col-md-6" *ngIf="analysisDetails.analysisSubtype == 'Sensitivity'"><strong>Sensitivity Type:
                    </strong>{{analysisDetails.ada_subtype}}</div>

                <div class="col-md-6"
                    *ngIf="analysisDetails.ada_subtype == 'Screening' && analysisDetails.analysisSubtype == 'Sensitivity'">
                    <strong>Screening Cutpoint: </strong>{{ analysisDetails.screening_cutpoint }}
                </div>
                <div class="col-md-6"
                    *ngIf="analysisDetails.ada_subtype == 'Confirmatory' && analysisDetails.analysisSubtype == 'Sensitivity'">
                    <strong>Confirmatory Cutpoint: </strong>{{ analysisDetails.confirmatory_cutpoint }}
                </div>
                <div class="col-md-6"
                    *ngIf="analysisDetails.ada_subtype == 'Screening & Confirmatory' && analysisDetails.analysisSubtype == 'Sensitivity'">
                    <strong>Screening Cutpoint: </strong>{{ analysisDetails.screening_cutpoint }}
                </div>
                <div class="col-md-6"
                    *ngIf="analysisDetails.ada_subtype == 'Screening & Confirmatory' && analysisDetails.analysisSubtype == 'Sensitivity'">
                    <strong>Confirmatory Cutpoint: </strong>{{ analysisDetails.confirmatory_cutpoint }}
                </div>
                <div class="col-md-6"
                    *ngIf="analysisDetails.analysisSubtype === 'Sensitivity' || analysisDetails.analysisSubtype === 'Cut Point & Sensitivity'">
                    <strong>Regression Type: </strong>
                    {{ analysisDetails.PL }}
                </div>
                <div class="col-md-6" *ngIf="analysisDetails.analysisSubtype == 'Cut Point'">
                    <strong>Cut Point Type: </strong>{{ analysisDetails.ada_subtype }}
                </div>
                <div class="col-md-6" *ngIf="analysisDetails.analysisSubtype == 'Cut Point & Sensitivity'">
                    <strong>Cut Point & Sensitivity Type: </strong>{{ analysisDetails.ada_subtype }}
                </div>
                <div class="col-md-6"><strong>Project Code:</strong> {{analysisDetails.projectCodeSA}}</div>
                <div class="col-md-6"><strong>Tukey Coefficient: </strong>{{analysisDetails.tukeyCoefficient}}</div>
                <!-- <div class="col-md-6"><strong>Are the Negative Control Values included?(Yes/No):
                    </strong>{{analysisDetails.negativeControlValues}}</div> -->
                <div class="col-md-6"><strong>Concentration Unit: </strong>{{ analysisDetails.concentrationUnit }}</div>
                <div class="col-md-6"><strong>NAB Dataset(Yes/No):
                    </strong>{{analysisDetails.nabDataset}}
                </div>
            </div>
        </div>

        <div *ngIf="analysisDetails.analysisType=='AP'">
            <div class="row">
                <div class="col-md-6"><strong>Analyte Name: </strong>{{analysisDetails.analyteNames}}</div>
                <div class="col-md-6"><strong>Project Code:</strong> {{analysisDetails.projectCode_AP}}</div>
            </div>
        </div>

        <app-doc-upload [analysisDetails]="analysisDetails"></app-doc-upload>
    </ng-template>
</card>