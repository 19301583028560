import {throwError as observableThrowError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class MainMenuService {
    constructor(private http: HttpClient) {
    }

    public getData(userid: string, rank: string) {
        // overriding rank as observer is failing on first login
        rank = sessionStorage.getItem('access');
        let URL = 'assets/data/main-menu.json';
        if (rank === 'GA' || rank === 'SU') {
            URL = 'assets/data/admin-main-menu.json';
        } else if (rank === 'RV') {
            URL = 'assets/data/rv-main-menu.json';
        }
        return this.http.get(URL);
    }

    public handleError(error: any) {
        return observableThrowError(error.error || 'Server Error');
    }
}
