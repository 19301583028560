<!-- <section name="form section"> -->
<!--  <div class="row">-->
<!--    <div class="col-md-6">-->
<!--      <mat-form-field>-->
<!--        <mat-label>Select User to fetch Login and Analysis Data</mat-label>-->
<!--        <mat-select [(value)]="loginSelected">-->
<!--          <mat-option *ngFor="let user of users" [value]="user.value">-->
<!--            {{ user.viewValue }}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->

      <!-- <mat-form-field>
                <mat-label>Select User to fetch Analysis Data</mat-label>
                <mat-select [(value)]="analysisSelected">
                    <mat-option *ngFor="let user of users" [value]="user.value">
                        {{user.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->

<!--      <mat-form-field appearance="fill">-->
<!--        <mat-label>Month and Year</mat-label>-->
<!--        <input matInput [matDatepicker]="dp" [max]="maxYear" [formControl]="date" />-->
<!--        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>-->
<!--        <mat-datepicker-->
<!--          #dp-->
<!--          startView="multi-year"-->
<!--          (yearSelected)="chosenYearHandler($event)"-->
<!--          (monthSelected)="chosenMonthHandler($event, dp)"-->
<!--          panelClass="example-month-picker"-->
<!--        >-->
<!--        </mat-datepicker>-->
<!--      </mat-form-field>-->
<!--      <div class="my-2">-->
<!--        <button mat-raised-button color="accent" (click)="fetchUserData()">-->
<!--          Get Data-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--&lt;!&ndash;    <div class="col-md-6">&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="row d-flex justify-content-sm-center">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="col-md-4 text-center" *ngIf="logdata.length > 0">&ndash;&gt;-->
<!--&lt;!&ndash;          <a target="_blank" href="{{ fileLink }}">&ndash;&gt;-->
<!--&lt;!&ndash;            <p><strong>Login Report</strong></p>&ndash;&gt;-->
<!--&lt;!&ndash;            <file [type]="'pdf'" [title]="'Output'"></file>&ndash;&gt;-->
<!--&lt;!&ndash;          </a>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="col-md-4" *ngIf="previousAnalysisKeys.length > 0">&ndash;&gt;-->
<!--&lt;!&ndash;          <a target="_blank" href="{{ pAfileLink }}">&ndash;&gt;-->
<!--&lt;!&ndash;            <p><strong>Analysis Report</strong></p>&ndash;&gt;-->
<!--&lt;!&ndash;            <file [type]="'pdf'" [title]="'Output'"></file>&ndash;&gt;-->
<!--&lt;!&ndash;          </a>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
<!-- </section> -->


<section name="data section" class="my-5">
  <!-- <div class="row">
        <div class="col-md-6">
            <h3>Login Data</h3>
            <div *ngIf="logdata.length > 0; else logspin">
                <app-auth-log [authLogs]="logdata"></app-auth-log>
            </div>   
        </div>
        <div class="col-md-6">
            <h3>Analysis Data</h3>
            <div *ngIf="previousAnalysisKeys.length > 0; else spin">
                <app-details [searchResults]="previousAnalysisKeys"></app-details>
            </div>
        </div>
    </div> -->
  <div class="col-md-10">

    

    <mat-form-field appearance="fill" class='matwidth'>
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker" >
        <input matStartDate placeholder="Start date"  #dateRangeStart>
        <input matEndDate placeholder="End date"  #dateRangeEnd  >
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
        <br>
    <button class="btn-success" (click)='selectedDates(dateRangeStart, dateRangeEnd)' >submit</button>
    
 

    <!-- <h3>Login Data</h3> -->
    <!-- <div *ngIf="logdata.length > 0; else logspin">
      <app-auth-log [authLogs]="logdata"></app-auth-log>
    </div> -->
  </div>
  <div class="col-md-10 my-5">
    <h3>Analysis Data</h3>
    <div *ngIf="previousAnalysisKeys.length > 0; else spin">
      <app-details  [searchResults]="previousAnalysisKeys"></app-details>
    </div>
  </div>
</section>

<ng-template #logspin>
  <div>
    <mat-spinner
      style="margin: 0 auto"
      mode="indeterminate"
      [diameter]="200"
    ></mat-spinner>
  </div>
</ng-template>

<ng-template #spin>
  <div>
    <mat-spinner
      style="margin: 0 auto"
      mode="indeterminate"
      [diameter]="200"
    ></mat-spinner>
  </div>
</ng-template>

<ng-template #noDataFound>
    <h3>No date found for {{ signedInUser.username }}</h3>
</ng-template>


<router-outlet name="print"></router-outlet>
