import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-help',
  templateUrl: './dialog-help.component.html',
  styleUrls: ['./dialog-help.component.scss']
})
export class DialogHelpComponent implements OnInit {

  name = 'ABC';

  // food;

  constructor(private dialogRef: MatDialogRef<DialogHelpComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.name = data.name;
  }

  @HostListener('document:keyup.escape') onClose() {
    this.onCancel();
  }

  onCancel() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  // onSubmit() {
  //   this.dialogRef.close(this.food);
  // }

}
