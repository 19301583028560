import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit {

    name = 'XYZ';

    activity;
    xData;
    label;
    options: any;


    constructor(private dialogRef: MatDialogRef<GraphComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any
    ) {
        this.name = data.name;
    }

    @HostListener('document:keyup.escape') onClose() {
        this.onCancel();
    }

    onCancel() {
        this.dialogRef.close();
    }

    ngOnInit(): void {
    }

    // onSubmit() {
    //   this.dialogRef.close(this.food);
    // }

}
