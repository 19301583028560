import {Component, OnInit} from '@angular/core';

import {MainMenuItem} from './main-menu-item';
import {MainMenuService} from './main-menu.service';
import {User} from '../../model/types';
import {AuthService} from '../../service';
import {AuthNewService} from '../../service/auth-new.service';
import {Router} from '@angular/router';
import { delay } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    selector: 'main-menu',
    templateUrl: 'main-menu.component.html',
    styleUrls: ['main-menu.component.scss']
})
export class MainMenuComponent  {
    mainMenuItems: MainMenuItem[];
    signedInUser: any;
    userRank: string;

    constructor(private authService: AuthNewService,
                private router: Router,
                private mainMenuService: MainMenuService) {
    }

    ngOnInit(): void {
        this.authService.getCurrentUser();
        this.authService.getCurrentLoginUser.pipe(delay(1000)).subscribe((user: any) => {
            // console.log(user);
        this.signedInUser = user;
        if (!this.signedInUser) {
            this.router.navigate(['/extra-layout/signin']);
            return;
        } else {
            this.getMainMenuItems(this.signedInUser.username);
        }
        });
    }

    getMainMenuItems(user: string): void {
        const OBSERVER = {
            next: x => {
                x.rank = this.userRank;
                this.mainMenuItems = x;
            }
        };
        this.mainMenuService.getData(user, this.userRank).subscribe(OBSERVER);
    }

    toggle(event: Event, item: any, el: any) {
        event.preventDefault();

        const items: any[] = el.mainMenuItems;

        if (item.active) {
            item.active = false;
        } else {
            for (let i = 0; i < items.length; i++) {
                items[i].active = false;
            }
            item.active = true;
        }
    }
}
