export const environment = {
    production: false,
    serverUrl: 'https://n0wergpdek.execute-api.us-east-2.amazonaws.com/dev',
    bucket: 'ariadne-prod-upload',
    userPool: {
        UserPoolId: 'us-east-2_N1o7BXdGc',
        ClientId: 'n5bi5uhd3h7gimkjevgfg9moq',
        region: 'us-east-2'
    },
    identityPoolId: 'us-east-2:daf94a19-e345-43e0-a0fb-877bf609c92d',

    defaultRegion: 'us-east-2',
    cognito: {
        identityPoolId: 'us-east-2:daf94a19-e345-43e0-a0fb-877bf609c92d', // REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-2', // REQUIRED - Amazon Cognito Region
        userPoolId: 'us-east-2_N1o7BXdGc', // OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: 'n5bi5uhd3h7gimkjevgfg9moq', // OPTIONAL - Amazon Cognito Web Client ID
    },
    Storage: {
        AWSS3: {
            bucket: 'ariadne-prod-upload', // REQUIRED -  Amazon S3 bucket
            region: 'us-east-2', // OPTIONAL -  Amazon service region
        }
    }
};

const awsconfig = {
    'aws_project_region': 'us-east-2',
    'aws_cognito_region': 'us-east-2',
    'aws_user_pools_id': 'us-east-2_N1o7BXdGc',
    'aws_user_pools_web_client_id': 'n5bi5uhd3h7gimkjevgfg9moq',
    'aws_cognito_mfa_configuration': 'OPTIONAL',

};
export default awsconfig;
