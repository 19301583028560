<section class="header_area" style="border-bottom: 2px solid rgb(248, 179, 179);">
    <div class="header_navbar">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand mx-4" href="https://www.ariadnesolutions.com/" target="_blank">
                            <img src="assets/img/RedThread_Clear_Registered.png" class="img-fluid" width="400" height="70"
                                alt="Logo">
                        </a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                            <ng-container>
                                <ul id="nav" class="navbar-nav ml-auto">
                                    <li class="nav-item active">
                                        <a class="page-scroll" [routerLink]="['/extra-layout/signin']" selected
                                            [hidden]="disableSignIn">Sign In</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="page-scroll" [routerLink]="['/extra-layout/home']" selected
                                            [hidden]="disableHome">Home</a>
                                    </li>
                                </ul>
                            </ng-container>
                        </button>

                        <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                            <ul id="nav" class="navbar-nav ml-auto mr-5">
                                <ng-template #elseBlock>
                                    <li class="nav-item active">
                                        <a class="page-scroll" [routerLink]="['/extra-layout/signin']" selected
                                            [hidden]="disableSignIn" style="font-size: 20px;">Sign In</a>
                                    </li>
                                    <li class="nav-item ml-3">
                                        <a class="page-scroll" [routerLink]="['/extra-layout/home']" selected
                                            [hidden]="disableHome" style="font-size: 20px;">Home</a>
                                    </li>            
                                </ng-template>
                                <div class="nav-item dropdown" *ngIf="signedInUserName; else elseBlock">
                                    <button mat-raised-button color="primary" style="border-radius: 40px"
                                        [routerLink]="['/default-layout/dashboard']" selected>
                                        {{signedInUserName}}
                                    </button>
                                    <button mat-raised-button color="warn" class="ml-3" style="border-radius: 40px"
                                        [routerLink]="['/extra-layout/signout']" selected >Sign Out</button>
                                </div>

                            </ul>
                        </div> <!-- navbar collapse -->
                    </nav> <!-- navbar -->
                </div>
            </div> <!-- row -->
        </div> <!-- container -->
    </div> <!-- header navbar -->
</section>