<section>
    <app-header></app-header>


    <!--====== FEATURES PART START ======-->
    <div class="container-fluid">

        <div id="home" class="header_hero d-lg-flex align-items-center"
            style="background-image: url('/assets/img/back.jpg'); background-repeat: no-repeat; background-attachment: fixed; background-size: cover;">
            <div class="mx-5">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="header_hero_content">
                            <h2 class="header_title">Compliant with FDA and EMA guidances and best practices. <br>
                                <span style="color: rgb(192, 39, 44);">Red Thread</span>
                                <p class="text-white">Enabling Biopharma to build a complete Bioanalytical profile<br> at the
                                    earliest stages of
                                    drug development.</p>
                            </h2>
                            <!-- <a [routerLink]="['/extra-layout/blog']" class="main-btn my-5">Explore</a> -->
                        </div> <!-- header hero content -->
                    </div>
                </div> <!-- row -->
            </div> <!-- container -->

            <!-- <div class="header_image d-flex align-items-center">
                <div class="image">
                    <img src="assets/img/logo-img.jpg" style="margin-left: 60px; border-radius: 20px;" width="500px"
                        class="img-fluid" alt="header image">
                </div>
            </div> -->
        </div>
        <div class="container m-auto">
            <section id="features" class="features_area pt-52 bg-white my-5">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-9">
                            <div class="section_title text-center pb-25">
                                <h4 class="title"> Red Thread</h4>
                                <p>
                                    Following FDA and EMA guidance and industry best practices, Red Thread’s modules
                                    support
                                    preclinical and clinical activities for regulated bioanalysis.
                                </p>
                            </div>
                        </div>
                    </div> <!-- row -->
                    <div class="row text-center">
                        <div class="col-lg-3 col-sm-6">
                            <div class="single_features mt-30">
                                <img src="assets/img/precision-red.jpg" width="100" class="img-fluid" alt="Precision">
                                <h5 class="title">Precision</h5>
                                <p>Red Thread delivers objectivity and rigor to scientific review, quality
                                    control, and
                                    quality assurance. It automates time-intensive processes for large volume data
                                    review
                                    and
                                    regulatory inspection preparation. Virtually every format, from raw data to
                                    finalized
                                    study
                                    report is supported by Red Thread.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="single_features mt-30">
                                <img src="assets/img/speed-red.jpg" width="100" class="img-fluid" alt="Speed">
                                <h5 class="title">Speed</h5>
                                <p>
                                    Advanced analytics provide rapid and efficient data review, with the ability
                                    to
                                    quickly detect trends of importance, or potential compliance issues. Red Thread
                                    dramatically
                                    reduces time-consuming tasks for scientific staff. High volume bioanalytical data
                                    reviews can be
                                    completed in hours, not days.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="single_features mt-30">
                                <img src="assets/img/depth-red.jpg" width="100" class="img-fluid" alt="Depth">
                                <h5 class="title">Depth</h5>
                                <p>
                                    High sensitivity analysis offers granularity that goes way beyond failure
                                    identification. Red Thread helps users find root causes and identify near-failure
                                    patterns,
                                    often the source of longer-term threats to drug development integrity. Red Thread
                                    unleash the
                                    exponential power of advanced computational techniques for bioanalytical data
                                    review.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="single_features mt-30">
                                <img src="assets/img/visualization-red.jpg" width="100" class="img-fluid"
                                    alt="Visualization">
                                <h5 class="title">Visualization</h5>
                                <p>
                                    It enables decision makers to see analytics presented visually, so they can
                                    grasp
                                    difficult concepts or identify new patterns. With interactive visualization, you can
                                    take the
                                    concept a step further by using technology to drill down into charts and graphs for
                                    more
                                    detail,
                                    interactively changing what data you see and how it’s processed.
                                </p>
                            </div>
                        </div>
                    </div> <!-- row -->
                </div> <!-- container -->
            </section>

            <!--====== FEATURES PART ENDS ======-->

            <!--====== VIDEO PART START ======-->

            <section id="video" class="video_area bg-white">

                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-9">
                            <div class="section_title text-center pb-25">
                                <h4 class="title">Getting Started Guide</h4>
                            </div>
                        </div>
                    </div> <!-- row -->
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="video_image mt-30">
                                <img src="assets/img/video.png" alt="video image" (click)="openDialog('Link')"
                                    style="cursor: pointer;">
                            </div> <!-- video image -->
                        </div>
                    </div> <!-- row -->
                </div> <!-- container -->
            </section>

            <!--====== VIDEO PART ENDS ======-->

            <section id="client" class="client_area pt-125 bg-white">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-9">
                            <div class="section_title text-center pb-55">
                                <h4 class="title">Red Thread Tools</h4>
                            </div>
                        </div>
                    </div> <!-- row -->
                    <div class="row client_active">
                        <div class="col-lg-5">
                            <div class="single_client text-center">
                                <div class="client_image">
                                    <img src="assets/img/small-molecule-red.jpg" alt="author">
                                </div>
                                <div class="client_content">
                                    <h4 class="author_name">Small Molecule</h4>
                                    <div class="my-5 mx-5">
                                        <ul style="display: block; list-style-type: disc;">
                                            <li style="text-align: left;">Method Validation</li>
                                            <li style="text-align: left;">Sample Analysis</li>
                                            <li style="text-align: left;">Report Preparation</li>
                                            <li style="text-align: left;">Audit Review and Findings Reporting</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> <!-- single client -->
                        </div>
                        <div class="col-sm-1"></div>
                        <div class="col-lg-5">
                            <div class="single_client text-center">
                                <div class="client_image">
                                    <img src="assets/img/biologics-red.jpg" alt="author">
                                </div>
                                <div class="client_content">
                                    <h4 class="author_name">Biologics</h4>
                                    <div class="my-5 mx-5">
                                        <ul style="display: block; list-style-type: disc;">
                                            <li style="text-align: left;">Assay Validation</li>
                                            <li style="text-align: left;">Sample Analysis</li>
                                            <li style="text-align: left;">Report Preparation</li>
                                            <li style="text-align: left;">Audit Review and Findings Reporting</li>
                                        </ul>
                                    </div>
                                </div>
                            </div> <!-- single client -->
                        </div>

                    </div> <!-- row -->
                </div> <!-- container -->
            </section>
        </div>


        <!--====== CLIENT PART ENDS ======-->


    </div>

    <app-footer></app-footer>
</section>