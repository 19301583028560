<div class="site-navbar">
  <div class="navbar-button" [ngClass]="{ 'open' : openedSidebar }" (click)="openSidebar()">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>

  <!-- <h1 class="page-title">{{ title }}</h1> -->

  <div class="nav-items">

    <ng-template #elseBlock>
      <ul style="text-decoration: none;">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/extra-layout/signin']">Sign In</a>
        </li>
      </ul>
    </ng-template>


    <div class="nav-item">
      <!-- <mat-chip-list >
        <mat-chip selected>  -->
         
          <button class="pl-4 pr-4 buttonNavbar buttonNavbar1 bg-secondary text-white" (click)="openDialog('HELP')">Help</button>

            <!-- <div class="name">Help</div>
          </button>
        </mat-chip>
      </mat-chip-list > -->
    </div>
  
    <div class="nav-item" *ngIf="signedInUserName; else elseBlock">
      <!-- <mat-chip-list > -->
        <!-- <mat-chip color="primary">  -->
          <button class="pl-3 pr-3 buttonNavbar buttonNavbar2 text-dark" (click)="open($event)">
            <!-- <div class="name"> -->
              {{signedInUserName}}
            <!-- </div> -->
          </button>
        <!-- </mat-chip> -->
      <!-- </mat-chip-list > -->
      <div class="dropdown-menu mini-menu" *ngIf="!openedSidebar">
        <ul>
          <li>
            <a [routerLink]="['/default-layout/user-details']" ><em class="fa fa-home"></em> My Account</a>
          </li>
 
          <li>
            <a [routerLink]="['/extra-layout/signout']"><em class="fa fa-sign-out"></em> Log Out</a>
          </li>
        </ul>
      </div>
      <div  class="dropdown-menu mini-menu"  *ngIf="openedSidebar" style="right: 220px;">
        <ul>
          <li>
            <a [routerLink]="['/default-layout/user-info']" ><em class="fa fa-home"></em> My Account</a>
          </li>
 
          <li>
            <a [routerLink]="['/extra-layout/signout']"><em class="fa fa-sign-out"></em> Log Out</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="items-overlay" (click)="close($event)">
    </div>
    <div class="nav-item" *ngIf="openedSidebar">
      <div style="margin-right: 220px;"></div>
    </div>

  </div>
</div>
