import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {AnalysisKey, RDSAnalysisData} from '../../../model/analysis-details.model';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  dataSource: MatTableDataSource<RDSAnalysisData>;
  tableColumns: string[] = ['projectCode', 'fileName', 'analysisDate', 'status' , 'userId'];

  @ViewChild('htmlData') htmlData1!: ElementRef;
  @Input() searchResults: RDSAnalysisData[] = [];

  constructor() {
  }

  ngOnInit() {
    // console.log('Init ', this.searchResults);
    this.dataSource = new MatTableDataSource(this.searchResults);
    console.log(this.dataSource)
  
    setTimeout(() => this.dataSource.paginator = this.paginator);
    
    setTimeout(() => this.dataSource.sort = this.sort);
    
  }

  getUrl(analysisType: string) {
    if (analysisType === 'RSG') {
      return '/default-layout/analysis-details';
    } else if (analysisType === 'SMR' || analysisType === 'LMR' || analysisType === 'ADA') {
      return '/default-layout/molecule-analysis';
    } else {
      return '/default-layout/notfound';
    }
  }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  openPDF() {
    const DATA: any = document.getElementById('reportData');
    html2canvas(DATA).then((canvas) => {
      const fileWidth = 208;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      const PDF = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('Report-analysis.pdf');
    });
  }
}
