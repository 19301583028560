import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../../service';
import {NewUser} from '../../../../model/types';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {
  verificationForm: FormGroup;
  submitted = false;
  @Input() model: NewUser;
  constructor(private authService: AuthService,
              private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.verificationForm = this.formBuilder.group({
      user_name: ['', Validators.required],
      verificationCode: ['', Validators.required]
    });
  }

  get form() { return this.verificationForm.controls; }

  onConfirm() {
    this.submitted = true;
    const userName = this.verificationForm.controls['user_name'].value;
    const verificationCode = this.verificationForm.controls['verificationCode'].value;
    this.authService.confirmUser(userName, verificationCode);
  }

}
