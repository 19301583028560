<!-- <app-header></app-header>
<div class="row justify-content-center">
  <div class="col-lg-4 text-center element-center">
    <form #mfaForm="ngForm" (ngSubmit)="confirm($event,Code.value)" method="post">
      <h2 class="form-signin-heading text-capitalize">MFA Required</h2>
      <p *ngIf="statusMessage" class="alert {{statusClass}}" >
        {{statusMessage}}
      </p>
        <div class="form-group">
          <label for="inputUsername" class="sr-only">Verification Code</label>
          <input type="text" id="mfaCode" [(ngModel)]="code" placeholder="Verification Code" class="form-control" name="code"
            required autofocus #Code/>
            <p>{{ code }}</p>
          <div *ngIf="formErrors && formErrors.mfaCodeRequired" class="alert alert-danger">
            {{ formErrors.mfaCodeRequired }}
          </div>
        </div>
        <div *ngIf="submissionError" class="alert alert-danger">
          {{ submissionError }}
        </div>
  
  
        <div class="form-group">
          <button class="btn btn-lg btn-primary text-capitalize btn-block" type='submit' [disabled]="!mfaForm.form.valid || submitted">
            <i class="fa fa-lock" aria-hidden="true"></i>
            Confirm
          </button>
        </div>
    </form>
    <app-loading *ngIf="submitted"></app-loading>
  </div>
</div>
<app-footer></app-footer> -->


<!-- <div class="form-group">
  <div class="form-group font-weight-bold">
      <input id="mfaCode" required type="text" maxlength="6" minlength="6" class="form-control"
             length="40" #code>
  </div>
  <div class="font-weight-bold">
    <h6>
      Authentication code has been sent to: {{destination}}
    </h6>  
  </div>
  <br>

  <div class="form-group text-center element-center">
    <button (click)="onSubmit(code.value)" type="submit" class="btn btn-info center-block">
      Submit
  </button>
  &nbsp;&nbsp;&nbsp;&nbsp;
  <button (click)="onCancel()" class="btn btn-danger center-block" ><a [routerLink]="['/default-layout/dashboard']" style="color: white;">Cancel</a></button>
  </div>
</div>
 -->

<div class="row justify-content-center">
  <div class="text-center element-center">
    <form [formGroup]="mfaForm" (ngSubmit)="onSubmit(code.value)" novalidate class="my-5">
      <div class="form-group">
        <mat-form-field appearance="legacy" style="font-size: 15px;">
          <mat-label>Authentication Code</mat-label>
          <input type="text" matInput formControlName="otp" placeholder="Authentication OTP" name="otp"
            [ngClass]="{ 'is-invalid': formControls.otp.errors }" #code required>
          <mat-error *ngIf="formControls.otp.errors" class="invalid-feedback error">
            <div *ngIf="formControls.otp.errors.required">Authentication Code is <strong>required</strong></div>
            <!-- <div *ngIf="formControls.otp.errors.minlength">OTP size minimum<strong>4 Digits</strong></div>
            <div *ngIf="formControls.otp.errors.maxlength">OTP size maximum<strong>6 Digits</strong></div> -->
          </mat-error>
        </mat-form-field>
      </div>
      <div class="font-weight-bold mb-5">
        <h6>
          Authentication code has been sent to: {{destination}}
        </h6>
      </div>
      <button [disabled]="!mfaForm.valid" class="btn btn-primary btn-md mr-3" type="submit">
        Submit
        <i *ngIf="mfaForm.invalid" class="fa fa-lock" aria-hidden="true"></i>
      </button>

      <button class="btn btn-danger btn-md mx-1">
        <a [routerLink]="['/default-layout/dashboard']" style="color: white;">Back</a>
      </button>

    </form>
    <!-- <app-loading></app-loading> -->
  </div>
</div>
<!-- <app-footer></app-footer> -->