<card class="mb-0">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
  <div class="col-md-4 text-right">
    <button class="btn btn-success btn-block" (click)="openPDF()">Download PDF</button>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort matSortStart="desc" id="reportData">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <ng-container matColumnDef="projectCode">
      <th mat-header-cell *matHeaderCellDef > Project Code</th>
      <td mat-cell *matCellDef="let element">{{element.project_code}}
    </ng-container>
    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef>File Name</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.analysis_status === 'Duplicate'; else linkRow">
          {{element.file_name}}
        </div>
        <ng-template #linkRow>
          <a [routerLink]="['/default-layout/molecule-analysis', element.analysis_id, element.user_id, {type: 'previous'}]">{{element.file_name}} </a>
        </ng-template>

      </td>
    </ng-container>

    <ng-container matColumnDef="analysisDate">
      <th mat-header-cell *matHeaderCellDef > Analysis Date (UTC Time Zone)</th>
      <td mat-cell *matCellDef="let element"> {{element.analysis_date}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status</th>
      <td mat-cell *matCellDef="let element"> {{element.analysis_status}} </td>
    </ng-container>
    <ng-container matColumnDef="userId">
      <th mat-header-cell *matHeaderCellDef > User ID </th>
      <td mat-cell *matCellDef="let element">{{element.user_id}}
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
  </table>

 
  
  <mat-paginator  [pageSizeOptions]="[ 10, 20, 50]" showFirstLastButtons></mat-paginator>
</card>



