import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss']
})
export class UserPageComponent implements OnInit {

  name;
  email;
  phoneNumber;
  password;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.fullPayloadData.subscribe((payload) => {
      console.log(payload);
        this.name = payload['cognito:username'];
        this.email = payload?.email;
        this.phoneNumber = payload?.phone_number;
    })
  }

}
