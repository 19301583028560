<app-header></app-header>
<section *ngIf="!codeRecieved">
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <div class="card my-5">
                <div class="card-body">
                    <h5 class="card-title text-center">Forget Password</h5>
                    <form class="my-3" [formGroup]="forgetPasswordForm" (ngSubmit)="UserForgetPassword()" novalidate>
                        <div class="form-group">
                            <mat-form-field appearance="legacy" style="font-size: 16px">
                              <mat-label>User Name</mat-label>
                              <input type="text" matInput formControlName="username" placeholder="User Name" name="username"
                                [ngClass]="{
                                  'is-invalid': submitted && formControls.username.errors
                                }" />
                              <mat-error *ngIf="formControls.username.errors" class="invalid-feedback error">
                                <div *ngIf="formControls.username.errors.required">
                                  Username is <strong>required</strong>
                                </div>
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <button [disabled]="!forgetPasswordForm.valid || submitted" mat-raised-button color="accent" type="submit"
                          style="font-size: 16px">
                          Submit
                        </button>
                    </form>
                </div>
                <ng-container *ngIf="errorMessageForgot">
                    <p class="text-danger mx-3">{{ errorMessageForgot }}</p>
                </ng-container>
                
            </div>
        </div>
        <div class="col-md-4">
            
        </div>
    </div>
</section>

<section *ngIf="codeRecieved">
    <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <div class="card my-5">
                <div class="card-body">
                    <h5 class="card-title text-center">Update Password</h5>
                    <form class="my-3" [formGroup]="forgetPasswordConfirmForm" (ngSubmit)="UserForgetPasswordConfirm()"
                        novalidate>
                        <div class="form-group">
                            <mat-form-field appearance="legacy" style="font-size: 16px">
                                <mat-label>Code</mat-label>
                                <input type="text" matInput formControlName="code" placeholder="Confirmation Code" name="code"
                                  [ngClass]="{
                                    'is-invalid': submitted && formControls1.code.errors
                                  }" />
                                <mat-error *ngIf="formControls1.code.errors" class="invalid-feedback error">
                                  <div *ngIf="formControls1.code.errors.required">
                                    Code is <strong>required</strong>
                                  </div>
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="legacy" style="font-size: 16px">
                                <mat-label>New Password</mat-label>
                                <input type="text" matInput formControlName="password" placeholder="New Password" name="password"
                                  [ngClass]="{
                                    'is-invalid': submitted && formControls1.password.errors
                                  }" />
                                <mat-error *ngIf="formControls1.password.errors" class="invalid-feedback error">
                                  <div *ngIf="formControls1.password.errors.required">
                                    Password is <strong>required</strong>
                                  </div>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        
                        <button [disabled]="!forgetPasswordConfirmForm.valid || submitted" mat-raised-button color="accent" type="submit"
                          style="font-size: 16px">
                          Submit
                        </button>
                    </form>
                </div>
                <ng-container *ngIf="errorMessageConfirm">
                    <p class="text-danger mx-3">{{ errorMessageConfirm }}</p>
                </ng-container>
            </div>
        </div>
        <div class="col-md-4"></div>
        
        
    </div>
</section>