
  <div class="row">
    <div class="col-md-12">
      <card>
        <strong>Items Analyzed in the Past:</strong>
        <span class="fa-stack fa-xs mx-3" style="color: royalblue; cursor: pointer;" (click)="openDialog('BAR')">
          <em class="fa fa-circle fa-stack-2x"></em>
          <em class="fa fa-info fa-stack-1x fa-inverse"></em>
        </span>
        <canvas baseChart height="80"
                [datasets]="barChartData"
                [labels]="barChartLabels"
                [colors]="chartColors"
                [options]="barChartOptions"
                [legend]="barChartLegend"
                [chartType]="barChartType"
                (chartClick)="onBarChartClick($event)">
        </canvas>
      </card>
    </div>
  </div>

  <div class="row">
    <ng-container >
      <div class="col-md-12" [hidden]="adaFlag == undefined">
        <card>
          <strong>ADA All Cut Point Summary Table</strong><br>
          <strong>Reports processed on - {{ adaDate }}</strong>
          <br><br><br>
          <div class="table table-responsive">
            <div [innerHTML]="tableData" class="table table-striped my-3 "></div>
          </div>
        </card>
      </div>
    </ng-container>
  </div>

  <div class="row my-3">
    <div class="col-md-6">
      <mat-card style="height: 100%;">
        <strong>Reports processed on - {{lineChart.ChartId}}</strong>
        <span class="fa-stack fa-xs mx-3" style="color: royalblue; cursor: pointer;" (click)="openDialog('LINE')">
          <em class="fa fa-circle fa-stack-2x"></em>
          <em class="fa fa-info fa-stack-1x fa-inverse"></em>
        </span>
        <canvas
                baseChart height="180"
                [chartType]="lineChart.ChartType"
                [datasets]="lineChart.ChartData"
                [labels]="lineChart.ChartLabels"
                [colors]="processing_colors"
                [options]="lineChartOptions"
                [legend]="true"
                (chartClick)="onLineChartClick($event)">
        </canvas>
      </mat-card>
    </div>
    <div class="col-md-6">
      <mat-card style="height: 100%;">
        <strong>Reports processed on - {{ lineChart.ChartId }}</strong>
        <br><br><br>
        <div class="table-responsive">
          <table class="table table-bordered">
            <tr>
              <th>Info</th>
              <th *ngFor="let label of lineChart.ChartLabels">{{label}}</th>
            </tr>
            <tr *ngFor="let d of lineChart.ChartData">
              <td *ngIf="d.label=='Error'; else warn"><badge [customColor]="'#EEC2B7'">{{d.label}}</badge> </td>
              <ng-template #warn>
               <td *ngIf="d.label=='Warning';else info"> <badge [customColor]="'#F8F8A9'">{{d.label}}</badge></td>
              </ng-template>
              <ng-template #info>
                <td><badge [customColor]="'#C5EEB2'">{{d.label}}</badge></td>
              </ng-template>
              <td *ngFor="let label of lineChart.ChartLabels; let j=index">{{d && d.data[j]}}</td>
            </tr>
          </table>
        </div>
      </mat-card>
    </div>

  </div>

  <!-- <div *ngIf="horizontalChart.AnalysisType !== 'ADA'"> -->
  <div class="row my-3">
    <div class="col-md-12 mb-5">
      <card>
        <strong>Analyze Report/Data - <a [routerLink]="[getUrl(horizontalChart.AnalysisType), horizontalChart.AnalysisId, horizontalChart.UserId]">{{horizontalChart.ChartId}}</a></strong>
        <canvas baseChart height="90"
                [datasets]="horizontalChart.ChartData"
                [labels]="horizontalChart.ChartLabels"
                [options]="hozBarChartOptions"
                [colors]="processing_colors"
                [legend]="true"
                [chartType]="hozBarChartType">
        </canvas>
      </card>
    </div>
  </div>

