import {Injectable} from '@angular/core';
import {
    Dashboard,
    SearchResult,
    Summary,
    AnalysisData,
    PreviousAnalysis, PreviousAnalysisData, AnalysisKey, AuthLog, Logs, AWSResponse, RDSAnalysisData,
} from '../model/analysis-details.model';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs/Subject';
import {Table} from '../model/small-molecule.model';
import {AuthService} from './auth.service';
import {environment} from '../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {throwError} from 'rxjs/internal/observable/throwError';

@Injectable({
    providedIn: 'root'
})
export class DBService {
    AnalysisURL: string = environment.serverUrl + '/analysis-data';
    RDSAnalysisURL: string = environment.serverUrl + '/rds_data';
    AuthURL: string = environment.serverUrl + '/auth-data';
    ValidationURL: string = environment.serverUrl + '/initiate-analysis';
    public searchResults: PreviousAnalysis[] = [];



    singleSearch = new Subject<SearchResult>();
    
    authLogs = new Subject<AuthLog[]>();
    userSearch = new Subject<PreviousAnalysis[]>();
    rdsAnalysisData = new Subject<RDSAnalysisData[]>();
    summary = new Subject<Summary>();



    constructor(private http: HttpClient,
                private authService: AuthService) {
        this.userSearch.subscribe(
            (data: PreviousAnalysis[]) => {
                // console.log('data', data);
                this.searchResults = data;
            }
        );
        // this.getDashBoardData();
    }

    getSummary(userId) {
        let params = new HttpParams();
        params = params.append('userId', userId);
        params = params.append('analysisId', null);
        params = params.append('type', 'summary');
        params = params.append('path', null);

        this.http.get(this.AnalysisURL, {params: params}).map(res => <Summary>res).subscribe(
            (data) => {
                this.summary.next(data);
            },
            (error: Error) => {
                console.log(error.message);
            }
        );

    }


    getUserAnalysis(userId) {
        let params = new HttpParams();
        params = params.append('userId', userId);
        params = params.append('analysisId', null);
        params = params.append('type', null);
        params = params.append('path', null);


        this.http.get(this.AnalysisURL, {params: params}).map(res => <PreviousAnalysisData>res).subscribe(
            (data) => {
                this.userSearch.next(data.data);
            },
            (error) => {
                console.log('Error in calling data from backend', error);
            }
        );

    }

    getAnalysis(access, startdate= 'none', enddate= 'none') {
        let params = new HttpParams();
        params = params.append('userId', sessionStorage.getItem('username'));
        params = params.append('organization', sessionStorage.getItem('organization'));
        params = params.append('group', sessionStorage.getItem('group'));
        params = params.append('access', access);
        params = params.append('startdate', startdate);
        params = params.append('enddate', enddate);
        console.log('Params ', params);

        this.http.get(this.RDSAnalysisURL, {params: params}).map(res => <RDSAnalysisData[]>res).subscribe(
            (data) => {
                this.rdsAnalysisData.next(data);
                sessionStorage.setItem('analysis-data', JSON.stringify(data));
            },
            (error) => {
                console.log('Error in calling data from backend', error);
            }
        );

    }

    getAnalysisDetails(userName, analysisId) {
        let params = new HttpParams();
        params = params.append('userId', userName);
        params = params.append('analysisId', analysisId);
        params = params.append('type', null);
        params = params.append('path', null);

        this.http.get(this.AnalysisURL, {params: params}).map(res => <AnalysisData>res).subscribe(
            (data) => {
                this.singleSearch.next(data.data);
            },
            (error) => {
                console.log('Error in calling data from backend', error);
            }
        );


    }


    submitTableData(tableArray: Table[], userId, analysisId) {

        const selectedTables = this.filerTable(tableArray);
        const updateObj = {
            'userId': userId,
            'analysisId': analysisId,
            'analysisStatus': 'TableSelected',
            'selectedTable': JSON.stringify(selectedTables)
        }

        // const AnalysisURL = 'assets/data/input.json';
        // return this.http.get(AnalysisURL);
        return this.http.post(this.AnalysisURL, updateObj)
    }

    filerTable(tableArray: Table[]) {
        const result: any = [];
        for (const table of tableArray) {
            if (table.valid) {
                const obj = {
                    'analyte_name': table.analyte_name,
                    'table_type': table.table_type,
                    'table_index': table.table_index,
                    'table_title': table.table_title,
                    'analysis_type' : table.analysis_type,
                    'tb_title' : table.tb_title,
                    'table_subtype': table.table_subtype
                }
                result.push(obj)
            }
        }
        return result
    }

    public getDashBoardData(): Dashboard[] {
        const dashBoardObj: Dashboard[] = [];
        for (const obj of this.searchResults) {
            const analysisDetails: AnalysisKey = JSON.parse('obj.key')
            const dbobj: Dashboard = {
                'userId' : obj.userId,
                'analysisDate' : new Date( parseInt(obj.analysisId, 10)),
                'analyteNames': this.getAnalyteNames(analysisDetails),
                'analysisType': analysisDetails.analysisType,
                'analysisSubtype': analysisDetails.analysisSubtype,
                'projectCodeSA': 'analysisDetails.projectCodeSA',
                'projectCodeMV': 'analysisDetails.projectCodeMV'
            }
            dashBoardObj.push(dbobj)
        }
        return dashBoardObj
    }

    public  getSAProjectCodes(): any[] {
        const mySet = new Set();
        const dashboardObjs = this.getDashBoardData();
        for (const obj of dashboardObjs) {
            if (obj.projectCodeSA) {
                mySet.add(obj.projectCodeSA)
            }
        }
        return Array.from(mySet.values());
    }

    public  getMVProjectCodes(): any[] {
        const mySet = new Set();
        const dashboardObjs = this.getDashBoardData();
        for (const obj of dashboardObjs) {
            if (obj.projectCodeMV) {
                mySet.add(obj.projectCodeMV)
            }
        }
        return Array.from(mySet.values());
    }

    public getAnalyteNames(analysisDetails) {
        const analytes: string[] = [];
        for (const analyte of analysisDetails.analytes) {
            analytes.push(analyte.analyteName)
        }
        return analytes
    }

    // Authentication
    recordAuthEvent(event: string) {
        const data = {'event': event};
        return this.http.post(this.AuthURL, data);
    }


    getAuthData(loginId, userId, LogstartDate= 'none', LogendDate= 'none') {
        let params = new HttpParams();
        params = params.append('loginId', loginId);
        params = params.append('userId', userId);
        params = params.append('start_date', LogstartDate);
        params = params.append('end_Date', LogendDate);
        console.log('Parameters to Backend', params);
        this.http.get(this.AuthURL, {params: params}).map(res => <AuthLog[]>res).subscribe(
            (logs) => {
                // console.log('Autho data from Backend', logs);
                this.authLogs.next(logs)
                sessionStorage.setItem('login-data', JSON.stringify(logs));
            },
            (error) => {
                console.log('Error in calling data from backend', error);
            }
        );

    }



   

    verifyAnalysis(analysisKeyObj: RDSAnalysisData): Observable<AWSResponse> {
        let params = new HttpParams();
        params = params.append('username', analysisKeyObj.user_id);
        params = params.append('group', analysisKeyObj.group_id);
        params = params.append('filename', analysisKeyObj.file_name);
        params = params.append('analysisId', analysisKeyObj.analysis_id)
        params = params.append('organization', analysisKeyObj.organization_id)
        params = params.append('analysis_type', analysisKeyObj.analysis_type)
        params = params.append('project_code', analysisKeyObj.project_code)
        return this.http.post<AWSResponse>(this.ValidationURL,
            {params: params}).pipe(
            tap(data => console.log('AWSResponse in verify Analysis: ', JSON.stringify(data))),
            catchError(this.handleError)
        );
    }
    private handleError(err: HttpErrorResponse): Observable<never> {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.message}`;
        }
        console.error(err);
        return throwError(() => errorMessage);
    }
}
