import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AnalysisDetails, SearchResult, Result, Table } from '../../model/analysis-details.model';
import { ActivatedRoute, Router } from '@angular/router';
import { DBService } from '../../service';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs';
import { AuthService } from '../../service';
import { User } from '../../model/types';
import { DownLoadService } from '../../service';
import { SharedService } from '../../layouts/shared-service';
import { HttpClient } from '@angular/common/http';
import { DropDownService } from '../../service/drop-down.service';
import { AppLoaderService } from '../../service/app-loader/app-loader.service';

@Component({
    selector: 'app-analysis-details',
    templateUrl: './analysis-details.component.html',
    styleUrls: ['./analysis-details.component.scss']
})
export class AnalysisDetailsComponent implements OnInit {
    pageTitle = 'Analysis Result';
    signedInUser: any;
    analysisId = '';
    public searchResults: SearchResult;
    analysisDetails: AnalysisDetails;
    routersub: Subscription;
    callerSubscription: Subscription;
    panelOpenState = false;
    title = 'Loading';
    public results: Result[];
    public tables: Table[] = [];
    step = 0;
    role = sessionStorage.getItem('access');


    constructor(private _sharedService: SharedService,
        private activatedroute: ActivatedRoute,
        private authService: AuthService,
        private dropDownService: DropDownService,
        private http: HttpClient,
        private router: Router,
        private dbService: DBService,
        private downloadService: DownLoadService,
        private loader: AppLoaderService) {
        this._sharedService.emitChange(this.pageTitle);

        this.dbService.singleSearch.subscribe(
            (data: SearchResult) => {
                this.searchResults = data;
                // if (this.searchResults.analysisStatus == "Complete") {
                //     this.closeDialog()
                // }
                // if (this.searchResults.analysisStatus !== "Complete") {
                //     this.openDialog()
                // }
                this.analysisDetails = JSON.parse(this.searchResults.analysisDetails);
                if (this.searchResults.analysisStatus === 'Complete') {
                    this.results = JSON.parse(this.searchResults.analysisResult);
                    const output_file = this.getSingedURL(this.searchResults.analysisResultFile);
                    const output_data = this.getSingedURL(this.searchResults.analysisResultData);
                    this.searchResults.analysisResultFile = output_file
                    this.searchResults.analysisResultData = output_data
                } else {
                    this.results = [];
                }
                // console.log('data expected in the array', this.searchResults.analysisResult);

            }
        );
    }
    openDialog() {
        this.loader.open(this.title);
        setTimeout(() => {
            this.loader.close();
          }, 15000)
    }
    closeDialog() {
        this.loader.close();
        // console.log('dialog closed')
    }

    ngOnInit() {

        const callInterval = Observable.interval(17000);
        this.authService.getCurrentUser((err, user: User) => {
            this.signedInUser = user;
            if (!this.signedInUser || !this.signedInUser.signedIn) {
                this.router.navigate(['/extra-layout/signin']);
                return;
            } else {
                this.routersub = this.activatedroute.paramMap.subscribe(params => {
                        this.analysisId = params.get('id');
                        this.dbService.getAnalysisDetails(this.signedInUser.username, this.analysisId);
                });
                this.callerSubscription = callInterval.subscribe(x => {
                    if ((this.searchResults.analysisResult.trim() !== '')) {
                        this.callerSubscription.unsubscribe();
                        this.closeDialog()
                    } else {
                        this.dbService.getAnalysisDetails(this.signedInUser.username, this.analysisId);
                    }
                })
            }
        });
    }


    getSingedURL(path: string) {
        if (path.indexOf('https') !== -1) {
            return path;
        }
        if (/\S/.test(path)) {
            return this.downloadService.getUrl(path);
        } else {
            return ' ';
        }
        const s3_path = this.downloadService.getUrl(path);
        // this.downloadFile(s3_path)

    }

    public downloadFile(downloadLink: string) {
        return this.http.get(downloadLink)
            .map((response) => {
                // @ts-ignore
                const blob = new Blob([response], { type: 'text/csv' });
                // console.log(blob)
            });
    }

    ngOnDestroy(): void {
        this.callerSubscription.unsubscribe();
    }
    getDisplayString(key) {
        return this.dropDownService.nameDescMap.get(key);
    }

    setStep(index: number) {
        this.step = index;
    }

    openGraph(message) {
        //
    }

}
